$verdeClaro: rgb(137, 211, 41);
$verdeEscuro: #003851;
$entrada: rgb(137, 211, 41);
$plus: #5291e4;
$cinza: #979ea8;

$produtividadeDark: rgb(0, 68, 34);
$produtividadeLight: rgb(3, 97, 50);

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

section.board-climate {
    min-height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;

    &.produtividade {
        .MuiButton-root.bt {
            background: $produtividadeDark;
        }

        .area-text .box-msgs.entrada h2,
        .area-text .box-msgs.entrada h4 {
            color: $produtividadeDark;
        }

        .area-text .box-msgs.plus h2,
        .area-text .box-msgs.plus h4 {
            color: $produtividadeDark;
        }

        .area-text .box-msgs.plus .bt {
            background: $produtividadeDark;
        }

        .area-text {
            right: auto;
            left: 0;
        }
    }

    .MuiButton-root.bt {
        background: $entrada;
        color: #fff;
        border-radius: 100px;
        width: 100%;
        position: relative;
        font-size: 17px;
        font-weight: normal;

        &:hover {
            background: $verdeEscuro;
        }
    }

    .area-image {
        width: 65vw;
        height: 100vh;
        // background: url(../../assets/board-climate/bg-board-climate.jpg) no-repeat center left / cover;
    }

    .area-text {
        width: 35vw;
        height: 100vh;
        background: #f0f0f0;
        padding: 25px 20px;
        overflow-y: auto;
        padding-top: 50px;

        .box-points {
            background: #fff;
            color: $cinza;
            padding: 7px 20px;
            border-radius: 5px;
            margin-bottom: 15px;

            .esq {
                text-align: center;
                width: 50%;

                h3 {
                    font-size: 35px;
                    font-weight: 500;
                    color: $verdeEscuro;
                    margin-top: -5px;
                    margin-bottom: -8px;
                    letter-spacing: 1px;
                }

                small {
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .right {
                border-left: solid 2px rgba($color: #000000, $alpha: 0.1);
                margin: -7px 0;
                padding-left: 15px;
                margin-left: 20px;
                width: calc(50% - 20px);

                >div {
                    text-align: center;
                }

                .flex {
                    margin-bottom: -5px;
                }

                small {
                    font-size: 12px;
                    font-weight: 500;
                }

                svg {
                    color: #f8b900;
                    font-size: 30px;
                    margin: 0 3px;

                    &.gray {
                        color: $cinza;
                    }
                }
            }
        }

        .box-msgs {
            background: #fff;
            color: #000;
            padding: 10px 20px;
            padding-bottom: 20px;
            border-radius: 5px;
            margin-bottom: 15px;
            position: relative;

            &.entrada {

                h2,
                h4 {
                    color: $entrada;
                }

                h4 {
                    color: #000;
                }
            }

            &.plus {

                h2,
                h4 {
                    color: $plus;
                }

                .bt {
                    background: $plus;

                    &:hover {
                        background: $verdeEscuro;
                    }
                }
            }

            img.icon {
                position: absolute;
                right: 10px;
                top: 15px;
            }

            h2 {
                font-size: 24px;
                font-weight: 500;
            }

            h3 {
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                margin: 10px 0;
                padding-right: 20px;
            }

            h4 {
                font-size: 13px;
                font-weight: 400;
                margin-bottom: 10px;
            }

            ol {
                li {
                    margin-bottom: 10px;
                    font-size: 14px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            p {
                font-size: 14px;

                strong {
                    font-weight: 500;
                }
            }

            >small {
                color: $cinza;
                font-size: 11px;
            }

            img.box {
                position: absolute;
                right: -15px;
                bottom: -15px;
            }
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        left: 5px;
        color: rgba($color: #000, $alpha: 0.2);
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;

        &:hover {
            svg {
                color: #333;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }
}

@media (max-width: 1024px) {

    section.board-climate .area-image,
    section.board-climate .area-text {
        width: 50vw;
    }


    section.board-climate .area-text .box-points {
        flex-wrap: wrap;
    }

    section.board-climate .area-text .box-points .esq {
        width: 100%;
    }

    section.board-climate .area-text .box-points .right svg {
        font-size: 24px;
        margin: 0 0;
    }

    section.board-climate .area-text .box-points .right {
        border-left: none;
        margin: 7px 0;
        padding-left: 0;
        margin-left: 0;
        padding-top: 10px;
        border-top: solid 2px rgba(0, 0, 0, 0.1);
    }

    section.board-climate .area-text .box-points .esq h3 {
        font-size: 30px;
    }
}

@media (max-width: 1024px) {

    section.board-climate .area-image,
    section.board-climate .area-text {
        width: 100vw;
    }

    section.board-climate .area-image {
        height: 20vh;
    }

    section.board-climate .area-text {
        height: 80vh;
    }

    section.board-climate .bt-fechar {
        top: calc(20vh + 5px);
    }

    section.board-climate .bt-fechar.climate.produtividade {
        left: auto;
        right: 5px;
    }

    .main-menu-hud .circle-button, .fullscreen-hud .circle-button {
        stroke-width: 75px !important;
    }
}