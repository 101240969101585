@import '../../variables.scss';

.ce-container .render-area {
    width: 100% !important;
    height: 100% !important;

    &::before {
        @include gradienteHeader();
        content: '';
        position: absolute;
        width: 100%;
        height: 150px;
        left: 0;
        top: 0;
        z-index: 11;
        opacity: 0.7;
    }

    video.layer {
        z-index: 12;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        height: 100%;
    }
}

@media (max-width: 1024px) {
    .ce-container .render-area {
        video.layer {
            width: 100%;
            height: auto;
        }
    }
}