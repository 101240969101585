$riscoDark: rgb(68, 50, 71);
$riscoLight: rgb(122, 82, 129);

$produtividadeDark: rgb(0, 68, 34);
$produtividadeLight: rgb(3, 97, 50);

$negocioDark: rgb(16, 56, 79);
$negocioLight: rgb(21, 90, 129);

$inovacaoDark: #d64951;

@mixin riscoGradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$riscoLight+0,00729d+100 */
    background: $riscoDark;
    /* Old browsers */
    background: -moz-linear-gradient(left, $riscoLight 0%, $riscoDark 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $riscoLight 0%, $riscoDark 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $riscoLight 0%, $riscoDark 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$riscoLight', endColorstr='$riscoDark', GradientType=1);
    /* IE6-9 */
}

@mixin produtividadeGradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$produtividadeLight+0,002e25+100 */
    background: $produtividadeLight;
    /* Old browsers */
    background: -moz-linear-gradient(left, $produtividadeLight 0%, $produtividadeDark 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $produtividadeLight 0%, $produtividadeDark 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $produtividadeLight 0%, $produtividadeDark 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$produtividadeLight', endColorstr='$produtividadeDark', GradientType=1);
    /* IE6-9 */

}

@mixin negociosGradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$negocioLight+0,002943+100 */
    background: $negocioLight;
    /* Old browsers */
    background: -moz-linear-gradient(left, $negocioLight 0%, $negocioDark 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $negocioLight 0%, $negocioDark 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $negocioLight 0%, $negocioDark 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$negocioLight', endColorstr='$negocioDark', GradientType=1);
    /* IE6-9 */

}

$inovacaoDark: rgb(137, 211, 41);
$inovacaoLight: rgb(172, 236, 88);

@mixin inovacaoGradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/$inovacaoLight+0,f64750+100 */
    background: $inovacaoLight;
    /* Old browsers */
    background: -moz-linear-gradient(left, $inovacaoLight 0%, $inovacaoDark 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $inovacaoLight 0%, $inovacaoDark 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $inovacaoLight 0%, $inovacaoDark 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$inovacaoLight', endColorstr='$inovacaoDark', GradientType=1);
    /* IE6-9 */
}

$verde: #89fa4f;
$dark: #003851;

.card-services {
    width: calc(25% - 20px);
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .box-img {
        height: 150px;
        background-size: cover;
        background-position: center center;
        position: relative;

        img {
            width: 50%;
            position: relative;
            left: 10px;

            &.aba {
                position: absolute;
                width: 100%;
                height: auto;
                bottom: -2px;
                left: 0;
            }
        }
    }

    .services-text {
        padding: 0 15px;
        width: 100%;
        height: calc(100% - 150px);
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-bottom: 20px;

        h3 {
            font-size: 12px;
            font-weight: 500;
            margin-top: -15px;
            position: relative;
        }

        h4 {
            font-size: 10px;
            color: #777;
            font-weight: 500;
            border-bottom: solid 1px;
            padding-bottom: 7px;
            margin-top: 2px;
        }

        p {
            font-size: 12px;
        }

        .MuiButton-root {
            color: #fff;
            border-radius: 100px;
            font-size: 11px;
            font-weight: 300;
            display: inline-block;
            width: 90%;
            padding: 3px;
            margin-bottom: 10px;

            &.produtividade {
                background: $produtividadeDark;
            }

            &.risco {
                background: $riscoDark;
            }

            &.negocios {
                background: $negocioDark;
            }

            &.inovacao,
            &.fieldview {
                background: $inovacaoDark;
            }

            &:hover {
                background: $verde;
                color: $dark;
            }
        }

        >i {
            position: absolute;
            width: 100%;
            height: 20px;
            bottom: 0;
            left: 0;

            &.produtividade {
                @include produtividadeGradient();
            }

            &.risco {
                @include riscoGradient();
            }

            &.negocios {
                @include negociosGradient();
            }

            &.inovacao,
            &.fieldview {
                @include inovacaoGradient();
            }
        }
    }
}