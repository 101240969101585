$dark: #223b51;
$spotify: #6ab85a;
$live: #f50000;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

body.boletim-do-clima {
    .render-area .layer:nth-child(2) {
        //z-index: 9999 !important;
    }
}

section.boletim-do-clima {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .area-image {
        width: 65%;
        height: 100%;
        // background: url(../../assets/boletim-do-clima/bg-clima.jpg) no-repeat center left / cover;
    }

    .area-text {
        width: 35%;
        height: 100%;
        background: #eff1f3;
        padding: 25px 20px;
        overflow-y: auto;
        z-index: 9999;
        position: relative;
        right: -100%;
        overflow-x: hidden;
        @include expoOut();

        &.active {
            right: 0;

            h1,
            >h3,
            .card,
            >p,
            .spotify,
            img[alt="Spotify"],
            .live,
            >p,
            img[alt="Live"] {
                left: 0;
                opacity: 1;
            }
        }

        h1 {
            font-weight: 500;
            color: $dark;
            font-size: 28px;
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 200ms;
            transition-duration: 1s;
        }

        >h3 {
            font-weight: 300;
            color: #8a8b8d;
            font-size: 17px;
            margin-bottom: 15px;
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 300ms;
            transition-duration: 1s;
        }

        .card {
            border-radius: 5px;
            overflow: hidden;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 400ms;
            transition-duration: 1s;

            img {
                width: 100%;

                &.tempo-icon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 50px;
                }
            }

            h3,
            p {
                padding: 0 15px;
            }

            h3 {
                margin-bottom: -5px;
                margin-top: 20px;
                font-weight: 500;
                font-size: 16px;
                color: $dark;
            }

            p {
                font-size: 12px;
                font-weight: 300;
                padding-bottom: 20px;
            }
        }

        >p {
            margin-bottom: 0;
            color: $live;
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 500ms;
            transition-duration: 1s;

            .MuiButtonBase-root {
                padding: 0;
                margin-right: 0;
                color: $live;
                text-decoration: underline;
            }

            strong {
                display: block;
            }
        }

        .spotify {
            color: $spotify;
            text-decoration: underline;
            padding: 0;
            border-radius: 0;
            margin-top: 20px;
            margin-bottom: 10px;
            line-height: 15px;
            text-transform: none;
            font-size: 14px;
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 500ms;
            transition-duration: 1s;
        }

        img[alt="Spotify"] {
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 600ms;
            transition-duration: 1s;
        }

        .live {
            color: $live;
            text-decoration: underline;
            padding: 0;
            border-radius: 0;
            margin-top: 20px;
            margin-bottom: 10px;
            line-height: 15px;
            text-transform: none;
            font-size: 14px;
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 700ms;
            transition-duration: 1s;
        }

        img[alt="Live"] {
            position: relative;
            left: 100px;
            opacity: 0;
            @include expoOut();
            transition-delay: 800ms;
            transition-duration: 1s;
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        right: 5px;
        color: rgba($color: #000000, $alpha: 0.2);
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;
        z-index: 9999;

        &:hover {
            svg {
                color: #333;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }
}

@media (max-width: 1024px) {

    body.boletim-do-clima {
        .layer {
            height: 35vh !important;
        }

        svg.layer {
            position: relative;
            left: calc(50% - 120px);
        }
    }

    section.boletim-do-clima {
        overflow: hidden;
        height: 100%;
        overflow-y: auto;

        .area-image {
            height: 35%;
            background: url(../../../assets/boletim-do-clima/mapa.png) no-repeat center center / cover;
            min-height: 200px;
        }

        .area-text {
            height: auto;
        }

        .bt-fechar {
            top: calc(35vh + 10px);
        }
    }

    section.boletim-do-clima .area-image,
    section.boletim-do-clima .area-text {
        width: 100%;
    }

    .render-area {
        >.layer {

            &:nth-child(1),
            &:nth-child(2) {
                left: 0;
                @include expoOut();
            }
        }
    }

    body.boletim-do-clima {
        .render-area {
            >.layer {
                position: relative;
                z-index: 9999;

                &:nth-child(1),
                &:nth-child(2) {
                    //left: -10%;
                }
            }
        }

        .cards {
            display: none;
        }

        .layer.fullscreen-hud {
            z-index: 99999;
        }

        .fot-logo {
            justify-content: flex-start;
            padding-left: 10px;
        }
    }
    
}