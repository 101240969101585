@import '../../variables.scss';

$verdeClaro: rgb(147, 209, 49);
$verdeEscuro: #003851;

.custom-chat-bot {

    position: absolute;
    z-index: 9999;
    height: calc(100% - 130px);
    width: 50%;
    left: 20%;
    bottom: 10px;

    &.Carol {
        a.rsc-header-close-button {
            //display: none;
        }

        h2.rsc-header-title {
            &::before {
                content: '';
                background: url(../../assets/chat/carol.png) no-repeat center center / cover;
                display: inline-block;
                width: 30px;
                height: 35px;
                margin-right: 10px;
            }
        }
    }

    &.João {
        h2.rsc-header-title {
            &::before {
                content: '';
                background: url(../../assets/chat/joao.png) no-repeat center center / cover;
                display: inline-block;
                width: 33px;
                height: 35px;
                margin-right: 10px;
            }
        }

        .custom-chat-bot .rsc-content>div:nth-child(2) ul.rsc-os-options {
            li {
                // &:nth-child(1){
                //     button {
                //         background: #112c45;
                //         border-color: #112c45;
                //         color: #fff;
                //     }
                // }
                // &:nth-child(2){
                //     button {
                //         background: #1f4d42;
                //         border-color: #1f4d42;
                //         color: #fff;
                //     }
                // }
                // &:nth-child(3){
                //     button {
                //         background: #d64851;
                //         border-color: #d64851;
                //         color: #fff;
                //     }
                // }
                // &:nth-child(4){
                //     button {
                //         background: #2e7199;
                //         border-color: #2e7199;
                //         color: #fff;
                //     }
                // }
            }
        }

        ul.rsc-os-options {
            li {
                // &:first-child {
                //     button {
                //         background: none !important;
                //         color: $verdeClaro;
                //     }
                // }
            }
        }
    }

    h2.rsc-header-title {
        font-family: 'Helvetica Neue';
        font-weight: 500;
        font-size: 18px;
        color: #273a4d;
        display: flex;
        align-items: center;
    }

    .rsc-header {
        box-shadow: 0 20px 15px rgba($color: #000000, $alpha: 0.3);
        position: relative;
        z-index: 9;
    }

    a.rsc-float-button {
        background: url(../../assets/chat/icon-oreinta.png);
        background-size: cover;
        width: 70px;
        height: 70px;
        border-radius: 0;
        overflow: unset;
        box-shadow: none;
        right: 5px;
        bottom: 0;
        position: absolute;
        z-index: 99;

        svg {
            display: none;
        }
    }

    .rsc-ts-image-container {
        display: none;
    }

    a.rsc-header-close-button {
        //display: none;
    }

    .rsc-container {
        max-height: calc(100% - 100px);
        height: 100%;
        position: absolute;
        z-index: 98;
        right: 125px;
    }

    .rsc-ts-bubble {
        @include gradienteAzul();
        margin: 0 15px;
        max-width: 100%;
        margin-bottom: 10px;
    }

    ul.rsc-os-options {
        li {
            width: 100%;
            margin-bottom: 7px;

            // &:first-child {
            //     button {
            //         background: $verdeClaro !important;
            //         color: #fff;

            //         &:hover {
            //             opacity: 1;
            //             background: $verdeEscuro !important;
            //             border-color: $verdeEscuro;
            //             color: $verdeClaro;
            //         }
            //     }
            // }

            button {
                width: 100%;
                background: none !important;
                border: solid 2px $verdeClaro;
                color: $verdeClaro;
                box-shadow: none;
                font-size: 14px;
                cursor: pointer;
                text-transform: uppercase;
                @include easeOut();

                &:hover {
                    opacity: 1;
                    background: $verdeClaro !important;
                    color: #fff;
                }
            }
        }
    }

    .rsc-content {
        height: calc(100% - 58px) !important;
        margin-top: 0;
    }
}

.fechar-chat {
    opacity: 0;
    position: absolute !important;
    bottom: 84%;
    right: 130px;
    z-index: 99;

    &.at_0 {
        display: none;
    }
}

@media (max-width: 1024px) {
    .fechar-chat {
        bottom: 70%;
    }

    body.page-chat-carol .render-area .hud {
        //background: url(../../assets/panolens/chat-carol.jpg) no-repeat center left / cover;
    }
    body .ce-container .render-area video.layer {
        //opacity: 0;
    }

    body.loaded.page-chat-carol .ce-container .render-area video.layer {
        opacity: 1;
    }
}

@media screen and (max-width: 568px) {
    .fechar-chat {
        bottom: 58%;
        right: 14px;
    }

    .custom-chat-bot {

        &.chat-carol {
            left: auto;
            right: 7px;
            width: calc(100% - 30px);
            position: absolute;

            .rsc-container {
                height: calc(100% - 120px);
            }
        }

        .rsc-container {
            border-radius: 10px;
            bottom: 10px !important;
            left: initial !important;
            height: 100%;
            right: 8px !important;
            top: initial !important;
            width: 100%;
        }
    }
}