@import '../../variables.scss';

.inicio {
    align-self: center;
    @include flex();
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-wrap: wrap;
    background: url(../../assets/loading.[p=0].png) no-repeat center center / cover;
    padding-top: 40px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        background: rgba($color: #000000, $alpha: 0.7);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .itens {
        position: relative;

        .box-video {
            position: relative;
            transform: scale(0);
            width: 38%;
            margin-top: 10px;
            @include expoOut();

            &.active {
                transform: scale(1);
            }

            .youtube {
                text-align: center;

                iframe {
                    box-shadow: 0 0 100px rgba($color: #000000, $alpha: 1);
                    background: #000;
                    width: 38%;
                    height: 21%;
                }
            }

            .close-video {
                position: absolute;
                top: -10px;
                right: -10px;
                min-width: 30px;
                width: 30px;
                height: 30px;
                min-height: 30px;
                padding: 0;
            }
        }

        p.txt {
            text-align: center;
            color: #fff;
            width: 100%;
            margin: 1.5vw auto;
            padding: 0 10%;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 300;

            strong {
                color: $verdeClaro;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &.t3 {
                text-transform: none;
                margin-bottom: -10px;
            }

            &.t2 {
                text-transform: none;
                color: $verdeClaro;
            }
        }

        button {
            background: $verdeClaro;
            color: #003851;
            border-radius: 100px;
            font-size: 19px;
            font-weight: 500;
            padding: 10px 20px;
            text-transform: none;
            margin: 0 auto;
            @include easeOut();

            &:hover {
                background: #fff;
            }
        }

        .progress-p {
            text-align: center;
            width: 100%;

            .MuiCircularProgress-colorPrimary {
                color: $verdeClaro;
                width: 125px !important;
                height: 125px !important;
            }

            .MuiTypography-colorTextSecondary {
                color: #fff;
                font-size: 30px;
                font-weight: 600;

                p {
                    font-size: 10px;
                    font-weight: 300;
                    margin: 0;
                    margin-top: -10px;
                }
            }
        }

        .start {
            width: 100%;
            height: 100%;
            transition: background-color .5s;
            background: rgba(0, 0, 0, 0);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;

            &.enabled {
                background: rgba(0, 0, 0, .7);
            }
        }
    }
}

@media (max-width: 1024px) {
    .inicio {
        background: url(../../assets/Entrada_Mobile.jpg) no-repeat center center / cover;

        p.txt {
            font-size: 12px;
            padding: 0 5px;
            position: relative;
            margin: 1.9vw auto;

            strong {
                margin-bottom: 5px;
            }
        }

        button {
            font-size: 14px;
            padding: 4px 20px;
        }
    }

    body.loaded .ce-container .render-area video.layer {
        opacity: 0;
    }

    .panolens-container {
        @include easeOut();
    }
}