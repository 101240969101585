@import '../../variables.scss';

.bt-intro {
    width: 100%;
    position: absolute;
    bottom: 45px;
    left: 0;
    z-index: 99;

    .bt {
        background: $verdeClaro;
        color: $verdeEscuro;
        border-radius: 100px;
        font-size: 19px;
        font-weight: 500;
        padding: 10px 20px;
        text-transform: none;
        margin: 0 auto;
        @include easeOut();

        &:hover {
            background: #fff;
        }

        svg {
            background: $verdeEscuro;
            color: $verdeClaro;
            border-radius: 100%;
            margin-right: 10px;
        }
    }
}

.menu-foot {
    position: absolute;
    z-index: 9999;
    width: 100%;
    top: calc(100% - 80px);

    ul {
        text-align: center;

        li {
            display: inline-block;

            .bt {
                color: $verdeClaro;
                background: $verdeEscuro;
                padding: 0 20px;
                font-size: 14px;
                margin: 0 10px;
                text-transform: uppercase;
                font-weight: 300;

                &:hover {
                    background: $verdeClaro;
                    color: $verdeEscuro;
                }
            }
        }
    }
}