@import './variables.scss';
@import './bootstrap-grid.min.scss';

body {
  margin: 0;
}


* {
  outline: none;
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex-wr {
  flex-wrap: wrap;
}

.flex-ch {
  justify-content: center;
}

.flex-ri {
  justify-content: flex-end;
}

.flex-bv {
  align-items: flex-end;
}

.flex-tv {
  align-items: flex-start;
}

.flex-le {
  justify-content: flex-start;
}

.flex-sp {
  justify-content: space-between;
}

.flex-sa {
  justify-content: last-baseline;
  padding-top: 50px;
}

.flex-cv {
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $verdeClaro;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $verdeEscuro;
}

strong {
  font-weight: 500;
}

ul {
  list-style: none;
  padding: 0;
}

span {
  display: block;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
}

.background-pattern {
  background-repeat: repeat;
  background: rgb(0, 107, 133);
  background-image: url('./pattern.png'), radial-gradient(circle, rgba(0, 107, 133, 1) 0%, rgba(0, 82, 161, 1) 59%, rgba(0, 12, 33, 1) 100%);
}

.main-menu {
  font-size: 50px;
  color: #ff0000;
  justify-content: center;
  align-items: flex-end;
}

.main-menu .icon-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

#root {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hud-menu {
  display: flex;
  flex-direction: column;
}

.hud-button-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.hud-button-top {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.hud-button-bar .hud-button {
  margin-left: 0 !important;
}

.ce-container {
  .circle-button {
    stroke: $verdeClaro;
    stroke-width: 60px !important;
    fill: $verdeClaro;
    animation: animaCircle 1s steps(50) infinite alternate;

    &:hover {
      stroke: $verdeClaro;
    }

    &.blur {
      stroke-width: 0;
      fill: $verdeClaro;
      opacity: 0.3;

      &:hover {
        stroke: $verdeClaro;
      }
    }

    &.borda {
      opacity: 0.3;
      animation: animaBorda 1s steps(50) infinite alternate;

      &:hover {
        stroke: $verdeClaro;
      }
    }

    @keyframes animaBorda {
      from {
        stroke-width: 0;
      }

      to {
        stroke-width: 30px;
      }
    }

    @keyframes animaCircle {
      from {
        stroke-width: 0;
      }

      to {
        stroke-width: 10px;
      }
    }
  }

  .render-area .layer {
    overflow: hidden;
  }
}

.MuiButton-text.bt-home,
.MuiButton-text.bt-voltar-superior,
.MuiButton-text.bt-home-totem {
  background: none;
  color: #fff;
  z-index: 9999;
  padding: 0;
  font-weight: 300;
  @include easeOut();

  &:hover {
    color: $verdeClaro;
    background: none;
    font-weight: 700;

    svg {
      padding: 4px;
    }
  }

  svg {
    font-size: 30px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    background: $verdeClaro;
    color: $dark;
    border-radius: 100%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    @include expoOut();
    transition-duration: 1s;
  }
}

.MuiButton-root.bt {
  background: $verdeClaro;
  color: $verdeEscuro;
  border-radius: 100px;
  font-size: 19px;
  font-weight: 500;
  padding: 10px 20px;
  text-transform: none;
  margin: 0 auto;
  @include easeOut();

  &:hover {
    background: #fff;
  }

  svg {
    background: $verdeEscuro;
    color: $verdeClaro;
    border-radius: 100%;
    margin-right: 10px;
  }
}

.bt {
  background: $verdeClaro;
  color: #003851;
  border-radius: 100px;
  font-size: 19px;
  font-weight: 500;
  padding: 10px 20px;
  margin: 0 auto;
  @include easeOut();

  &:hover {
    background: #fff;
  }

  svg {
    background: $verdeEscuro;
    color: $verdeClaro;
    border-radius: 100%;
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {

  .MuiButton-text.bt-home,
  .MuiButton-text.bt-home-totem {

    .MuiButton-label {
      >span {
        display: none;
      }
    }
  }

  body.hide-audio {

    .MuiButton-text.bt-home,
    .MuiButton-text.bt-home-totem {}
  }
}

// APP ---
body .ps--active-x>.ps__rail-x,
body .ps--active-y>.ps__rail-y {
  background-color: #fff;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  opacity: 1;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

body .ps__thumb-y {
  background-color: $verdeClaro;
  border-radius: 6px;
  width: 4px;
  right: 2px;
}

body .ps__thumb-x {
  background-color: $verdeClaro;
  border-radius: 6px;
  height: 4px;
  top: 2px;
}

body .MuiSnackbar-root {
  z-index: 9999999;
}

.text-button {
  fill: white;
  font-size: 45px;
  cursor: pointer;
  text-shadow: 0px 0px 5px #1835a9;
  font-weight: 500;
  @include easeOut();

  &:hover {
    text-shadow: 0px 0px 5px #fff;
  }
}

body.south {
  .text-button.south {
    text-shadow: 0px 0px 5px #fff;
  }
}

body.north-east {
  .text-button.north-east {
    text-shadow: 0px 0px 5px #fff;
  }
}

body.north {
  .text-button.north {
    text-shadow: 0px 0px 5px #fff;
  }
}

body.midwest {
  .text-button.midwest {
    text-shadow: 0px 0px 5px #fff;
  }
}

body.south-west {
  .text-button.south-west {
    text-shadow: 0px 0px 5px #fff;
  }
}

.msg-vertical {
  display: none;
}

.msg-social-apps {
  display: none;

  &.ativo {
    display: flex;
    width: 100%;
    height: 100%;
    @include gradienteAzul();
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    color: $verdeClaro;
    flex-direction: column;
    padding: 10px;

    svg {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20%;
    }

    h3 {
      font-weight: normal;
    }
  }

  p {
    color: #fff;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .ico {
    position: relative;
    width: 55%;
    height: 100px;
    margin: 5vw auto;
    transform: scale(0.5) translateY(-40px);

    svg {
      color: $verdeClaro;
      font-size: 30%;
      position: absolute;
      top: 8%;
      left: 22%;

      &.vertical {
        position: absolute;
        transform: rotate(90deg) translateX(-5vw) translateY(24vw);
      }

      &.redo {
        position: absolute;
        font-size: 10%;
        top: 3%;
        left: 25%;
        transform: rotate(45deg);
      }
    }
  }

  h3 {
    font-size: 18px;
    display: block;
    width: 100%;
    color: $verdeClaro;
    font-weight: 300;
    letter-spacing: 1px;
    padding: 0 15px;
  }

  .bt {
    background: $verdeClaro;
    color: #003851;
    border-radius: 100px;
    font-size: 19px;
    font-weight: 500;
    padding: 10px 20px;
    margin: 0 auto;
    @include easeOut();

    &:hover {
      background: #fff;
    }

    svg {
      background: $verdeEscuro;
      color: $verdeClaro;
      border-radius: 100%;
      margin-right: 10px;
    }
  }

  .top {
    img {
      width: calc(100% - 25px);
      position: relative;
      left: -5px;
    }
  }

  >img {
    width: 65%;
    max-width: 170px;
    position: absolute;
    bottom: 10px;
  }
}

.bt-intro {
  width: 100%;
  position: absolute;
  bottom: 45px;
  left: 0;
  z-index: 99;

  .bt {
    background: $verdeClaro;
    color: $verdeEscuro;
    border-radius: 100px;
    font-size: 19px;
    font-weight: 500;
    padding: 10px 20px;
    text-transform: none;
    margin: 0 auto;
    @include easeOut();

    &:hover {
      background: #fff;
    }

    svg {
      background: $verdeEscuro;
      color: $verdeClaro;
      border-radius: 100%;
      margin-right: 10px;
    }
  }
}

.MuiButton-root.bt {
  background: $verdeClaro;
  color: $verdeEscuro;
  border-radius: 100px;
  font-size: 19px;
  font-weight: 500;
  padding: 10px 20px;
  text-transform: none;
  margin: 0 auto;
  @include easeOut();

  &:hover {
    background: #fff;
  }

  svg {
    background: $verdeEscuro;
    color: $verdeClaro;
    border-radius: 100%;
    margin-right: 10px;
  }
}

svg {
  .legenda-mobile {
    fill: #fff;
    font-size: 20px;
    display: none;
  }

  .legenda-mobile-bg {
    fill: rgba(0, 0, 0, 0.5) !important;
    border-radius: 5px !important;
    stroke: $verdeClaro !important;
    stroke-width: 2px !important;
    display: none;
  }
}

.chat-geral {
  position: fixed;
  bottom: 0;
  height: 1px;
  z-index: 9999;
}

#onetrust-banner-sdk {

  h3,
  a {
    font-weight: 500 !important;
  }

  a,
  #onetrust-pc-btn-handler {
    color: $verdeEscuro !important;
    font-weight: 500 !important;
  }
}

.ce-container .render-area {
  width: 100% !important;
  height: 100% !important;

  video.layer {
    z-index: 12;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    height: 100%;
  }
}

@media (orientation: portrait) {
  // .msg-vertical {
  //   display: flex;
  //   width: 100%;
  //   height: 100%;
  //   background: $verdeEscuro;
  //   text-align: center;
  //   z-index: 99999;
  //   position: absolute;
  //   left: 0;
  //   top: 0;

  //   .ico {
  //     position: relative;
  //     width: 55%;
  //     height: 35%;
  //     margin: 5vw auto;

  //     svg {
  //       color: $verdeClaro;
  //       font-size: 30%;
  //       position: absolute;
  //       top: 8%;
  //       left: 22%;

  //       &.vertical {
  //         position: absolute;
  //         transform: rotate(90deg) translateX(-5vw) translateY(24vw);
  //       }

  //       &.redo {
  //         position: absolute;
  //         font-size: 10%;
  //         top: 3%;
  //         left: 25%;
  //         transform: rotate(45deg);
  //       }
  //     }
  //   }

  //   h3 {
  //     font-size: 18px;
  //     display: block;
  //     width: 100%;
  //     color: $verdeClaro;
  //     font-weight: 300;
  //     letter-spacing: 1px;
  //     padding: 0 15px;
  //   }
  // }

  #root {
    .wrapper>*:not(.msg-vertical):not(.msg-social-apps) {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .bt-intro {
    bottom: auto;
    top: calc(100% - 75px);

    .bt {
      font-size: 15px;
      padding: 7px 15px;
    }
  }

  svg .legenda-mobile,
  svg .legenda-mobile-bg {
    display: block;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}