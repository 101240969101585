@import '../../variables.scss';

.text-button {
    fill: white;
    font-size: 45px;
    cursor: pointer;
    text-shadow: 0px 0px 5px #1835a9;
    font-weight: 500;
    @include easeOut();

    &:hover {
        text-shadow: 0px 0px 5px #fff;
    }
}

body.south {
    .text-button.south {
        text-shadow: 0px 0px 5px #fff;
    }
}

body.north-east {
    .text-button.north-east {
        text-shadow: 0px 0px 5px #fff;
    }
}

body.north {
    .text-button.north {
        text-shadow: 0px 0px 5px #fff;
    }
}

body.midwest {
    .text-button.midwest {
        text-shadow: 0px 0px 5px #fff;
    }
}

body.south-west {
    .text-button.south-west {
        text-shadow: 0px 0px 5px #fff;
    }
}


@media (max-width: 1024px) {
    ul.menu-mapa-mobile {
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 99999;

        li {
            margin-bottom: 10px;
            text-align: center;

            .bt {
                font-size: 14px;
                padding: 0px 10px;
            }
        }
    }

    .mapa svg {
        display: none;
    }
}