$verde: #89fa4f;
$shadow: #020202bf;
$light: #e9f1f3;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

section.youtube {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: $shadow;

    .video {
        margin: 0 auto;
        width: 100%;

        .video-container {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 50px;
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        right: 5px;
        color: $light;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;

        &:hover {
            svg {
                color: $verde;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }
}

@media (max-width: 1024px) {
    section.youtube .video .video-container iframe {
        position: relative;
        top: 0;
        left: 0;
        width: 95vw;
        height: 56vw;
        padding: 15px;
    }
}