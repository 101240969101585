$verde: #89fa4f;
$shadow: #020202bf;
$light: #e9f1f3;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

section.insurance {
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: $shadow;

    .screen {
        margin: 0 auto;
        position: relative;
        width: 100%;
        height: 100%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 50px;
            border: none;
        }
        p {
            background-color: $light;
            padding: 50px;
            margin: 0 auto;
            display: block;
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        right: 5px;
        color: $light;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;

        &:hover {
            svg {
                color: $verde;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }
}

@media (max-width: 1024px) {
    section.insurance .screen iframe {
        padding: 0;
    }
}