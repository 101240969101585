@import '../../variables.scss';

section.converse-bayer {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;

    &.animation-true {

        .area-title {
            left: 0;
        }

        .area-text {
            right: 0;
        }
    }

    #hyvor-talk-view {
        height: calc(100% - 145px) !important;

        iframe {
            height: calc(100% - 145px) !important;
        }
    }

    .area-title {
        width: 60%;
        height: 100%;
        background: $dark;
        top: 0;
        left: -50%;
        padding: 20px;
        padding-top: 0;
        position: absolute;
        @include expoOut();
        overflow-y: auto;

        h1 {
            font-size: 26px;
            color: #fff;
            font-weight: 500;
            line-height: 28px;
            padding: 10px 0;

            img {
                width: auto;
                margin-right: 15px;
            }
        }

        iframe {
            border: none;
            width: 100%;
            max-width: 560px;
            margin: 0 auto;
            display: block;
            margin-bottom: 10px;
        }

        h4 {
            font-size: 18px;
            color: $verde;
            font-weight: 500;
            margin: 0;
            margin-bottom: 7px;
        }

        .bts {
            margin-bottom: 7px;
        }

        p {
            color: #fff;
            font-size: 14px;
            margin: 0;
            margin-bottom: 7px;
            font-weight: 300;

            a,
            small {
                color: $verde;
            }

            img {
                width: 130px;
                display: inline-block;
                margin-top: 20px;
                position: relative;
                top: 10px;
                margin-left: 7px;
            }
        }

        .bt {
            font-size: 14px;
            padding: 3px 10px;
            margin-right: 10px;
            text-transform: uppercase;

            &.menor {
                margin-bottom: 10px;
            }
        }
    }

    .area-text {
        background: $light;
        color: $dark;
        width: 40%;
        height: 100%;
        padding: 20px 15px;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        right: -100%;
        @include expoOut();

        h5 {
            font-weight: 300;
            font-size: 18px;
            padding-right: 20px;
            border-bottom: solid 1px $dark;
            padding-bottom: 7px;
            margin-bottom: 15px;
        }

        h4 {
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 21px;
            font-weight: 500;
        }

        .bt.bt-entrar-forum {
            margin-top: 20px;
        }


        .bsikJu {
            width: 100%;
            height: calc(100% - 230px);
            overflow-x: hidden;
            overflow: auto;
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        right: 5px;
        color: $dark;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;
        @include expoOut();

        &:hover {
            svg {
                color: $verde;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }
}

@media (max-width: 1024px) {

    body section.converse-bayer {
        flex-wrap: wrap;
        overflow-y: auto;
        height: 100%;
        width: 100%;
    }

    body section.converse-bayer .area-text .bsikJu {
        width: 100%;
        height: 100%;
        min-height: 300px;
    }

    body section.converse-bayer .area-title {
        width: 100%;
        position: relative;
        height: auto;
    }

    body section.converse-bayer.animation-true .area-text {
        width: 100%;
        position: relative;
        height: auto;
        overflow-y: hidden;
    }

    body section.converse-bayer .bt-fechar {
        color: #fff;
        z-index: 999;
    }

    section.converse-bayer #hyvor-talk-view iframe {
        height: 300px !important;
    }

    section.converse-bayer .area-title .bt {
        margin-bottom: 10px;
    }
}

@media (max-width: 1024px) {
    section.converse-bayer .area-title iframe {
        width: 300px;
        height: 165px;
        margin-bottom: 15px;
    }
}