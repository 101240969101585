$dark: #003851;
$verde: #89fa4f;
$light: #e9f1f3;
$verdeClaro: #89fa4f;
$verdeEscuro: #003851;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}


section.chat-live {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: hidden;

    &.animation-true {

        .area-title {
            left: 0;

            h1,
            h2 {
                left: 0;
            }

            img {
                left: 0;
            }
        }

        .area-text,
        .area-text .youtube iframe,
        .area-text .login h4,
        .area-text .login .area-bt,
        .area-text .bt-comments {
            right: 0;
        }

        .bt-fechar {
            top: 5px;
        }

    }

    .MuiButton-root.bt {
        background: $verde;
        color: $dark;
        border-radius: 100px;
        position: relative;
        font-size: 19px;
        font-weight: 500;
        padding: 10px 30px;
        text-transform: none;
        position: relative;

        svg {
            position: absolute;
            left: 5px;
            top: 2px;
            font-size: 220%;
            transform: rotate(0deg);
            @include expoOut();
            transition-duration: 2s;
        }

        &:hover {
            background: $dark;
            color: $verde;
        }
    }

    .area-title {
        width: 30%;
        height: 100%;
        background: $dark;
        top: 0;
        left: -100%;
        position: absolute;
        @include expoOut();
        transition-duration: 1s;
        flex-direction: column;

        h1 {
            font-size: 55px;
            color: $verde;
            font-weight: 500;
            line-height: 59px;
            padding-left: 30px;
            padding-right: 20px;
            margin-bottom: 10px;
            position: relative;
            left: -100%;
            @include expoOut();
            transition-duration: 1s;
            transition-delay: 200ms;
            line-height: normal;
        }

        h2 {
            font-size: 31px;
            color: #fff;
            width: 100%;
            font-weight: 500;
            padding-left: 30px;
            position: relative;
            left: -100%;
            @include expoOut();
            transition-duration: 1s;
            transition-delay: 300ms;
        }

        >img {
            position: relative;
            top: 15px;
            left: -100%;
            @include expoOut();
            transition-duration: 1s;
            transition-delay: 400ms;
            width: 142px;
        }

        .box-carol {
            text-align: center;

            img {
                width: 35%;
                margin-right: 15px;
            }

            p {
                color: #fff;
                font-size: 14px;
                width: calc(65% - 15px);
                padding-right: 20px;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 1px;

                .MuiButton-root.bt {
                    background: $verde;
                    color: $dark;
                    border-radius: 100px;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 3px 25px;
                    text-transform: none;
                    position: relative;
                    margin-top: 15px;
                    font-weight: 500;

                    &:hover {
                        background: #fff;
                        color: $verdeEscuro;
                    }

                    &.branco {
                        background: #fff;
                        font-size: 14px;
                        font-weight: normal;

                        &:hover {
                            background: $verde;
                        }
                    }
                }
            }
        }
    }

    .area-text {
        background: $light;
        width: 70%;
        text-align: center;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        right: -100%;
        @include expoOut();
        transition-duration: 1s;

        .youtube {
            position: relative;
            padding-top: 15px;

            iframe {
                border: none;
                margin: 0 auto;
                box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.3);
                position: relative;
                right: -100%;
                width: calc(70vw - 28px);
                height: calc(40vw - 24px);
                @include expoOut();
                transition-duration: 1s;
                transition-delay: 200ms;
            }

            .bt-full {
                background: $verdeClaro;
                color: $verdeEscuro;
                position: absolute;
                right: 17px;
                bottom: 10px;
                z-index: 99999;
                font-size: 20px;
                min-width: 15px;
                height: 25px;
                width: 28px;
                z-index: 99999;
            }

            &.ativo {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9999;

                iframe {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .logado {
            display: flex;
            justify-content: center;

            &.full-true {
                position: fixed;
                bottom: 15px;
                left: calc(50% - 150px);
                z-index: 9999;
            }
        }

        .bt-comments {
            position: relative;
            right: -100%;
            color: $dark;
            background: none;
            display: flex;
            margin-top: 30px;
            margin-right: 15px;

            &:hover {
                background: none;

                svg {
                    background: $verdeClaro;
                    color: $dark;
                    border-color: $verdeClaro;
                }
            }

            svg {
                border: solid 1px;
                border-radius: 100%;
                padding: 7px;
                font-size: 45px;
                margin-right: 10px;
                @include easeOut();
                overflow: visible;
            }
        }

        .login {
            width: 65%;
            margin: 0 auto;

            h4 {
                font-size: 22px;
                color: $dark;
                text-align: center;
                font-weight: 500;
                margin: 10px 0;
                position: relative;
                right: -100%;
                @include expoOut();
                transition-duration: 1s;
                transition-delay: 300ms;
            }

            .area-bt {
                position: relative;
                right: -100%;
                @include expoOut();
                transition-duration: 1s;
                transition-delay: 400ms;
            }
        }
    }

    .modal-comments {
        width: 0;
        height: 0;
        bottom: 0;
        right: calc(100vw - 30vw);
        position: absolute;
        transform: scale(0);
        @include expoOut();
        z-index: 999999;
        visibility: hidden;
        border-radius: 7px;
        //box-shadow: 0 0 49px rgba(0, 0, 0, 0.3);

        >p {
            padding: 7px 15px;
        }

        &.active {
            transform: scale(1);
            visibility: visible;
            width: 30%;
            max-height: 97%;
            height: auto;
            overflow-y: auto;
        }

        .close-comments {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 30px;
            width: 30px;
            height: 30px;
            min-height: 30px;
            padding: 0;

            background: #fff;
            color: #003851;
            border-radius: 100px;
            font-size: 19px;
            font-weight: 500;
            text-transform: none;
            @include easeOut();

            &:hover {
                background: $verde;
            }
        }

        #hyvor-talk-view {
            max-width: 100%;
            width: 100%;
            height: 100%;
        }
    }

    .bt-fechar {
        position: absolute;
        right: 5px;
        color: $dark;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;
        top: -100px;
        @include expoOut();
        transition-duration: 1s;
        transition-delay: 500ms;

        &:hover {
            svg {
                color: $verde;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }

    .adm-banners {
        position: absolute;
        right: -200px;
        top: 100px;
        height: calc(100vh - 200px);
        width: 200px;
        background: #fff;
        padding: 15px;
        z-index: 99999;
        border-radius: 0 0 0 20px;
        @include expoOut();

        &.ativo-true {
            right: 0;

            .bt {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .bt {
            text-align: left;
            justify-content: flex-start;
            padding: 7px;
            padding-right: 0;
            border-radius: 50px 0 0 50px;
            right: 100%;
            margin: 0;
            top: 0;
            position: absolute;
            background: #fff;

            &:hover {
                background: #fff;

                svg {
                    background: $verde;
                    color: $verdeEscuro;
                }
            }

            svg {
                position: relative;
                top: 0;
                left: 0;
                transform: rotate(0deg);
                transition-duration: 700ms;
            }
        }

        .itens {
            overflow-y: auto;
            width: 100%;
            height: 100%;

            .item {
                cursor: pointer;
                margin-bottom: 10px;
                border: solid 3px rgba($color: $verde, $alpha: 0);

                &.ativo-true {
                    border: solid 3px $verde;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }

    .user-banners {
        position: absolute;
        left: calc(50% - 15vw);
        top: 0;
        height: auto;
        width: 30vw;
        padding: 0;
        z-index: 99999;
        overflow-y: auto;

        .item {
            cursor: pointer;
            margin-bottom: 10px;
            visibility: hidden;
            opacity: 0;
            height: 0;
            display: flex;
            justify-content: center;
            transform: scale(0);
            transition-duration: 1s;
            @include expoOut();

            &.ativo-true {
                visibility: visible;
                opacity: 1;
                margin-top: 20px;
                transform: scale(1);
                height: auto;
            }

            a {
                color: #fff;
                width: 100%;
                background: rgba($color: #000000, $alpha: 0.7);
                padding: 10px;
                border-radius: 10px;
                @include easeOut();
                position: relative;

                &:hover {
                    background: $verde;
                    color: $verdeEscuro;

                    .desc {
                        s {
                            color: rgba($color: $verdeEscuro, $alpha: 0.5);
                        }
                    }
                }

                .img {
                    width: 30%;
                    height: 70px;
                    max-width: 250px;
                    border-radius: 15px;
                    background-size: cover;
                    margin-right: 15px;
                }

                .desc {
                    font-size: 14px;
                    width: 70%;
                    padding-right: 30px;

                    strong {
                        margin-right: 5px;
                    }

                    s {
                        color: rgba($color: #fff, $alpha: 0.5);
                        font-size: 12px;
                    }
                }

                .MuiSvgIcon-root {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }
            }
        }
    }

    .user-produtos {
        position: absolute;
        left: 15px;
        bottom: 15px;
        max-height: 90vh;
        width: calc(30vw - 30px);
        padding: 0;
        z-index: 99999;
        background: rgba($color: #000000, $alpha: 0.5);
        padding: 20px 10px;
        border-radius: 10px;
        visibility: hidden;
        transform: scale(0);
        @include expoOut();

        &.ativo-true {
            transform: scale(1);
            visibility: visible;
        }

        .itens {
            overflow-y: auto;
            display: block;
            max-height: calc(90vh - 40px);

            .item {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;

                &:last-child {
                    margin: 0;
                }

                a {
                    color: #fff;
                    width: 100%;
                    background: rgba($color: #000000, $alpha: 0.7);
                    padding: 10px;
                    border-radius: 10px;
                    @include easeOut();
                    position: relative;

                    &:hover {
                        background: $verde;
                        color: $verdeEscuro;

                        .desc {
                            s {
                                color: rgba($color: $verdeEscuro, $alpha: 0.5);
                            }
                        }
                    }

                    .img {
                        width: 90px;
                        height: 90px;
                        border-radius: 15px;
                        background-size: cover;
                        margin-right: 15px;
                    }

                    .desc {
                        font-size: 14px;
                        width: calc(100% - 90px - 15px);
                        padding-right: 30px;

                        strong {
                            margin-right: 5px;
                        }

                        s {
                            color: rgba($color: #fff, $alpha: 0.5);
                            font-size: 12px;
                        }
                    }

                    .MuiSvgIcon-root {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                    }
                }
            }
        }

        .close-produtos {
            position: absolute;
            top: -10px;
            right: -10px;
            min-width: 30px;
            width: 30px;
            height: 30px;
            min-height: 30px;
            padding: 0;

            background: #fff;
            color: #003851;
            border-radius: 100px;
            font-size: 19px;
            font-weight: 500;
            text-transform: none;
            @include easeOut();

            &:hover {
                background: $verde;
            }
        }

        .duvida-produtos {
            position: absolute;
            top: -40px;
            left: 0;
            min-width: 30px;
            width: 30px;
            height: 30px;
            min-height: 30px;
            padding: 0;

            background: #fff;
            color: #003851;
            border-radius: 100px;
            font-size: 19px;
            font-weight: 500;
            text-transform: none;
            @include easeOut();

            &:hover {
                background: $verde;
            }
        }
    }

}

@media (max-width: 1024px) {
    section.chat-live .area-title h1 {
        font-size: 4%;
        line-height: 4%;
        padding-left: 4%;
    }

    section.chat-live .area-title h2 {
        font-size: 2%;
        line-height: 2%;
        padding-left: 4%;
    }

    section.chat-live .area-text {
        padding: 20px 20px;
        padding-top: 50px;
        width: 100%;
        height: 100vh;
    }

    section.chat-live .area-text .itens {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    section.chat-live .area-text .box-icon img {
        width: 75px;
    }

    section.chat-live .area-text .box-icon .txt h4 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    section.chat-live .area-text .box-icon .txt p {
        font-size: 16px;
    }

    section.chat-live .area-text iframe {
        width: 95vw;
        height: 53vw;
    }

    section.chat-live .area-text .login {
        width: 100%;
        margin-bottom: 20px;
        margin-top: -35px;
    }

    section.chat-live.animation-true .area-title {
        display: none;
    }

    section.chat-live .area-text .bt-comments {
        position: relative;
        right: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 5px;

        .MuiButton-label {
            >span {
                display: none;
            }
        }
    }

    section.chat-live .area-text .modal-comments {
        width: calc(100vw - 30px);
        height: calc(100% - 15px);
        bottom: 0;
        right: 20px;
    }

    section.chat-live.animation-true .bt-fechar {
        color: $verdeEscuro;
    }

    section.chat-live .modal-comments {
        &.active {
            width: calc(100vw - 30px);
            padding-left: 5px;
            height: calc(100% - 85px);
            bottom: 0;
            z-index: 99999;

            iframe {
                height: 100% !important;
            }
        }

        right: 15px;
    }

    section.chat-live .area-text .logado {
        flex-wrap: wrap;

        &.full-true {
            bottom: 15px;
            left: 15px;
            width: calc(100vw - 70px);
        }
    }

    section.chat-live .user-produtos {
        max-height: calc(100vh - 120px);
        width: calc(100vw - 30px);

        .itens {
            overflow-y: auto;
            display: block;
            max-height: calc(100vh - 160px);
        }
    }

    section.chat-live .area-text .youtube .bt-full {
        bottom: 27px;
        display: none;
    }

    section.chat-live.animation-true .bt-fechar {
        color: #fff;
        z-index: 9999;
        background: $verde;
        color: $verdeEscuro;
    }

    section.chat-live .area-text .bt-comments svg {
        background: $verde;
        border: none;
    }

    section.chat-live .user-banners {
        left: 10px;
        width: calc(100vw - 20px);
    }

    section.chat-live .user-banners .item a .img {
        width: 90px;
        height: 90px;
        max-width: 100%;
    }

    section.chat-live .user-banners .item a .desc {
        width: calc(100% - 90px - 15px);
    }
}

@media (orientation: portrait) {
    section.chat-live .area-text .youtube.ativo {
        iframe {
            transform: scale(1);
        }

        &.deu-play-true {
            iframe {
                transform: scale(3.5);
            }   
        }
    }

    section.chat-live .user-produtos {
        max-height: 50vh;
        width: calc(100vw - 30px);

        .itens {
            overflow-y: auto;
            display: block;
            max-height: calc(50vh - 40px);
        }
    }
}