
body.page-negocios {
    section.negocios {
        background: url(../../assets/panolens/negocios.jpg) no-repeat 65% center/cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}