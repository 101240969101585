@mixin expoOut {
  -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
  -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
  -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
  -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
  transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}


$dark: #003851;
$verdeClaro: #89fa4f;

.modal-wrapper {
  position: absolute;
  z-index: 99999;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0);
  height: 100%;
  overflow: hidden;
  @include easeOut();

  &.animation-true {
    background-color: rgba($color: #000000, $alpha: 0.7);

    article {
      top: 0;
    }
  }

  article {
    position: relative;
    top: 100%;
    @include expoOut();
    transition-duration: 1s;
    transition-delay: 200ms;
  }

  section {
    position: relative;
  }

  .bVaYPD {
    cursor: pointer;
    @include easeOut();

    &:hover {
      background: #fff;
      color: $dark;
    }
  }

  .bgCNBp {
    cursor: pointer;
    @include easeOut();

    &:hover {
      background: #0f3952;
      color: #fff;
    }
  }
}

.MuiButtonBase-root.closeModal {
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: white;
  border-radius: 50%;
  min-width: 35px;
  min-height: 35px;
  height: 35px;
  width: 35px;
  z-index: 30;
  padding: 3px;
  cursor: pointer;
  @include easeOut();

  &:hover {
    background: $verdeClaro;
  }

  &::after {
    content: '';
    background-image: url(./close.svg);
    width: 18px;
    height: 30px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
  }
}