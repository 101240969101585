@mixin expoOut {
    -webkit-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin gradienteHeader {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+88&1+0,0+88 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 88%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 88%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 88%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
}

@mixin gradienteAzul {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4a82fa+0,2a56b2+100 */
    background: #4a82fa;
    /* Old browsers */
    background: -moz-linear-gradient(top, #4a82fa 0%, #2a56b2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #4a82fa 0%, #2a56b2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #4a82fa 0%, #2a56b2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4a82fa', endColorstr='#2a56b2', GradientType=0);
    /* IE6-9 */
}

$cinza: #979ea8;

$amarelo: #f8b900;
$azul: #83d1eb;

$dark: #003851;
$verde: #89fa4f;
$light: #e9f1f3;
$verdeClaro: #89fa4f;
$verdeEscuro: #003851;