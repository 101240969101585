@import "../../variables";

body.expodireto {
    .ce-container {
        display: none;
    }

    .panolens-container {
        top: -1000000px;
        left: -100000000px;
    }
}

body.expodireto {
    header .bt.sound {
        left: -40px;
    }
}

header img {
    @include easeOut;
}

body.hide-logo {
    header img {
        opacity: 0;
        visibility: hidden;
    }
}

section.expodireto {
    color: #000;
    height: 100vh;
    overflow-y: auto;

    .banner {
        background: url(../../assets/lpshowrural/expodireto/bg-expodireto.png) no-repeat center top;
        height: 100vh;
        min-height: 600px;
        padding: 20px 50px;

        .top {

            .MuiButtonBase-root {
                color: #fff;
                text-decoration: underline;
            }

            img {
                margin-left: 15px;
                position: relative;
                top: 45px;
            }
        }

        .cols {
            .esq {
                margin-right: 70px;

                h1 {
                    color: $verde;
                    font-size: 42px;
                    line-height: 42px;
                    margin-top: 70px;
                    margin-bottom: 50px;
                }

                h2 {
                    color: $verdeEscuro;
                    font-size: 22px;
                    font-weight: 500;
                }

                p {
                    color: $verdeEscuro;
                    font-size: 17px;
                    font-weight: 300;
                }
            }

            .dir {
                width: 50%;
                text-align: center;

                img {
                    max-width: 100%;
                    margin-top: 185px;
                }
            }
        }

        .MuiButtonBase-root.closeModal {
            position: fixed;
            right: 10px;
            top: 10px;
            background-color: white;
            border-radius: 50%;
            min-width: 35px;
            min-height: 35px;
            height: 35px;
            width: 35px;
            z-index: 30;
            padding: 3px;
            cursor: pointer;
            @include easeOut();

            &:hover {
                background: $verdeClaro;
            }

            &::after {
                content: '';
                background-image: url(../../components/modal/close.svg);
                width: 18px;
                height: 30px;
                display: block;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
            }
        }
    }

    .banner-expo {
        padding: 30px 0;
        cursor: pointer;

        img {
            width: 100%;

            &.mobile {
                display: none;
            }
        }
    }

    .transmissoes {
        padding: 30px 0;

        .cols {
            .esq {
                color: $dark;

                h3 {
                    font-size: 65px;
                    text-transform: uppercase;
                    margin: 0;
                    margin-bottom: -45px;
                }

                h4 {
                    font-size: 100px;
                    text-transform: uppercase;
                    margin: 0;
                    margin-bottom: -45px;
                }

                p {
                    font-size: 18px;
                    font-weight: 300;
                }

                small {
                    font-size: 17px;
                    font-weight: 300;
                }
            }

            .itens {
                width: 65%;

                .item {
                    width: 209px;
                    cursor: pointer;
                    pointer-events: none;

                    &.ativo {
                        pointer-events: unset;
                    }

                    .thumb {
                        background: url(../../assets/lpshowrural/expodireto/thumb-video.jpg) no-repeat center left / 100%;
                        width: 209px;
                        height: 117px;

                        &.t1 {
                            background: url(../../assets/lpshowrural/expodireto/thumb1.png) no-repeat center left / 100%;
                        }

                        &.t2 {
                            background: url(../../assets/lpshowrural/expodireto/thumb2.png) no-repeat center left / 100%;
                        }

                        &.t3 {
                            background: url(../../assets/lpshowrural/expodireto/thumb3.png) no-repeat center left / 100%;
                        }
                    }

                    h5 {
                        margin: 7px 0;
                        border-bottom: solid 1px $dark;
                        padding-bottom: 5px;
                        margin-bottom: 15px;

                        &::before {
                            content: '';
                            width: 10px;
                            height: 10px;
                            border-radius: 20px;
                            display: inline-block;
                            background: red;
                            margin-right: 5px;
                        }

                        &.ativo {
                            &::before {
                                background: $verdeClaro;
                            }
                        }

                        span {
                            font-size: 12px;
                            font-weight: 300;
                            display: inline;
                        }
                    }

                    .box-calendario {
                        img {
                            margin-right: 10px;
                        }

                        span {
                            font-size: 17px;
                            font-weight: 300;
                        }
                    }
                }
            }

        }
    }

    .oportunidades {
        padding: 30px 0;
        background: #e5eae7;
        color: $dark;
        text-align: center;

        h2 {
            font-size: 34px;
        }

        h3 {
            font-size: 24px;
            font-weight: 300;
        }

        p {
            font-size: 24px;
            margin: 0;
        }

        .box-owl {
            border: solid 3px rgba(37, 59, 79, 0.2);
            padding: 20px 50px;
            border-radius: 1000px;

            .owl-carousel {
                max-width: 500px;
                margin: 0 auto;

                .item {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    img {
                        width: 214px;
                        height: auto;
                    }
                }
            }

            .owl-theme .owl-dots .owl-dot span {
                width: 15px;
                height: 15px;
                background: none;
                border: solid 2px #bfc7c9;
            }

            .owl-theme .owl-dots .owl-dot.active span,
            .owl-theme .owl-dots .owl-dot:hover span {
                background: #bfc7c9;
                border: solid 2px #bfc7c9;
            }

            .owl-nav {
                position: absolute;
                width: 100%;
                height: 0;
                margin: 0;
                display: flex;
                justify-content: space-between;
                top: calc(50% - 30px);

                button {
                    width: 13px;
                    height: 26px;

                    &.owl-prev {
                        background: url(../../assets/lpshowrural/seta-esq.png);
                        margin-left: -65px;
                    }

                    &.owl-next {
                        background: url(../../assets/lpshowrural/seta-dir.png);
                        margin-right: -65px;
                    }

                    span {
                        display: none;
                    }
                }
            }

            .owl-dots {
                position: relative;
                top: 12px;

                button {}
            }
        }
    }

    .expodireto {
        padding: 50px 0;
        color: $dark;

        .dir {
            padding-left: 35px;

            h4 {
                font-size: 23px;
            }

            h5 {
                font-size: 23px;
                font-weight: 300;
            }

            p {
                font-size: 18px;
                font-weight: 300;
                margin: 0;
            }
        }
    }

    .foot {
        background: $dark;
        padding: 20px 0;

        img {
            max-width: 200px;
        }
    }
}

.modal-expodireto {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    @include easeOut();

    &.ativo {
        opacity: 1;
        visibility: visible;
    }

    iframe {
        border: 0;
        max-width: calc(100% - 30px);
    }

    .MuiButtonBase-root.closeModal {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: white;
        border-radius: 50%;
        min-width: 35px;
        min-height: 35px;
        height: 35px;
        width: 35px;
        z-index: 30;
        padding: 3px;
        cursor: pointer;
        @include easeOut();

        &:hover {
            background: $verdeClaro;
        }

        &::after {
            content: '';
            background-image: url(../../components/modal/close.svg);
            width: 18px;
            height: 30px;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
        }
    }
}

@media (max-width: 1200px) {
    section.expodireto .banner .cols {
        align-items: flex-start;
    }

    section.expodireto .transmissoes .cols .itens .item {
        width: calc(33.33% - 10px);
    }

    section.expodireto .transmissoes .cols .itens .item .thumb {
        width: 100%;
    }

    section.expodireto .transmissoes .cols .esq h3 {
        font-size: 55px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: -35px;
    }

    section.expodireto .transmissoes .cols .esq h4 {
        font-size: 85px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: -35px;
    }

    section.expodireto .transmissoes .cols .esq p {
        font-size: 16px;
        font-weight: 300;
    }

    section.expodireto .transmissoes .cols .esq small {
        font-size: 15px;
        font-weight: 300;
    }

    section.expodireto .banner .cols .esq h1 {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

@media (max-width: 992px) {
    section.expodireto .banner .cols .esq h1 {
        margin-top: -30px;
        margin-bottom: 60px;
    }
}

@media (max-width: 1024px) {
    section.expodireto .banner .cols {
        align-items: center;
        padding-top: 45px;
    }

    section.expodireto .banner .cols .esq h1 {
        font-size: 28px;
        line-height: 28px;
    }

    section.expodireto .banner .cols .esq h2 {
        font-size: 18px;
        font-weight: 500;
    }

    section.expodireto .banner .cols .esq p {
        font-size: 14px;
        font-weight: 300;
    }

    section.expodireto .banner .cols .dir {
        width: 45%;
        padding-top: 50px;
    }

    section.expodireto .transmissoes .cols .esq h3 {
        font-size: 40px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: -30px;
    }

    section.expodireto .transmissoes .cols .esq h4 {
        font-size: 60px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: -25px;
    }

    section.expodireto .transmissoes .cols .esq p {
        font-size: 13px;
        font-weight: 300;
    }

    section.expodireto .transmissoes .cols .esq small {
        font-size: 12px;
        font-weight: 300;
    }

    section.expodireto .oportunidades h2 {
        font-size: 30px;
    }

    section.expodireto .oportunidades h3 {
        font-size: 18px;
        font-weight: 300;
    }
}

@media (max-width: 1024px) {
    section.expodireto .banner {
        padding: 20px 0px;
        height: auto;
    }

    section.expodireto .banner .cols {
        justify-content: center;
    }

    section.expodireto .banner .cols .esq {
        margin-right: 0;
        margin-top: -80px;
        width: 100%;
        text-align: center;
    }

    section.expodireto .transmissoes .cols .itens {
        width: 100%;
        justify-content: center;
        padding-top: 25px;
        max-width: 350px;
        margin: 0 auto;
    }

    section.expodireto .transmissoes .cols .itens .item {
        width: 250px;
        margin-bottom: 25px;
    }

    section.expodireto .banner .cols .esq h1 {
        padding-right: 0;
        margin-top: 100px;
    }

    section.expodireto .banner .top img {
        margin-left: 0;
        right: 10px;
    }

    section.expodireto .banner .cols .dir {
        width: 100%;
        padding-top: 35px;
        text-align: center;

        img {
            max-width: 300px;
        }
    }

    section.expodireto .expodireto .flex {
        flex-wrap: wrap;
        justify-content: center;
    }

    section.expodireto .expodireto .dir {
        padding-left: 0;
        text-align: center;
        padding-top: 25px;
        width: 100%;
    }

    section.expodireto .foot {
        text-align: center;
        padding-bottom: 60px;
        margin-bottom: 50px;
    }

    section.expodireto .foot img {
        width: 100%;
        max-width: 200px;
    }

    section.expodireto .oportunidades .box-owl .owl-carousel .item img {
        width: 140px;
        height: auto;
        display: inline-block;
    }

    section.expodireto .oportunidades .box-owl .owl-nav button.owl-next {
        margin-right: -25px;
    }

    section.expodireto .oportunidades .box-owl .owl-nav button.owl-prev {
        margin-left: -25px;
    }

    section.expodireto .transmissoes .cols .esq {
        text-align: center;
        width: 100%;
    }

    section.expodireto .banner .cols .dir img {
        margin-top: -20px;
    }

    section.expodireto .transmissoes .cols .esq h4 {
        margin-bottom: 40px;
    }

    section.expodireto {
        .banner-expo {
    
            img {
                &.desktop {
                    display: none;
                }
    
                &.mobile {
                    display: block;
                }
            }
        }
    }
}