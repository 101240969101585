@import '../../variables.scss';
@import './hamburgers.min.scss';

body {

    &.hide-audio {
        header .bt.sound {
            display: none;
        }
    }

    .bt-home,
    .bt-home-totem,
    .bt-voltar-superior {
        display: none;
    }

    &.bt-voltar-superior-ativo {
        .bt-home-totem {
            display: none;
        }

        .bt-voltar-superior {
            display: inline-flex;
        }

        .render-area .bt-home {
            display: none;
        }
    }

    &.bt-inicio_totem-ativo {
        .bt-home-totem {
            display: inline-flex;
            z-index: 99999;
        }

        .bt-voltar-superior {
            display: none;
        }

        .render-area .bt-home {
            display: none;
        }
    }

    &.bt-voltar-ativo {
        .bt-home {
            display: inline-flex;
        }

        .bt-voltar-superior {
            display: none;
        }

        .render-area .bt-home {
            display: none;
        }
    }

    .cards {
        display: none;
    }

    &.home-inferior .cards,
    &.home-superior .cards {
        display: block;
    }
}

nav {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 101;
    visibility: hidden;
    overflow: hidden;

    button {
        pointer-events: auto;
        cursor: pointer;
    }


    &.ativo {
        visibility: visible;
        z-index: 99999;

        .info-us {
            left: 0;
        }

        .area-menu {
            right: 0;
        }
    }

    .not-logged {
        height: calc(100% - 200px);
        margin-top: -35px;
        margin-bottom: 70px;

        p {
            color: $verdeClaro;
            font-size: 21px;
            font-weight: 300;
            margin-top: 10px;
            letter-spacing: 1px;

            strong {
                font-weight: 500;
            }
        }

        .MuiButton-root {
            background: $verdeClaro;
            color: $verdeEscuro;
            border-radius: 100px;
            position: relative;
            font-size: 17px;
            font-weight: normal;
            padding: 5px 30px;

            &:hover {
                background: #fff;
                color: $verdeEscuro;
            }

            &.cad {
                margin-left: 20px;
                background: rgba($color: $verdeClaro, $alpha: 0);
                border: solid 1px $verdeClaro;
                color: $verdeClaro;

                &:hover {
                    background: $verdeClaro;
                    color: $verdeEscuro;
                }
            }
        }
    }

    .info-us {
        background: $verdeEscuro;
        min-height: 100%;
        padding: 20px;
        color: #fff;
        width: 40%;
        flex-direction: column;
        padding-top: 100px;
        left: -40%;
        position: relative;
        @include expoOut();
        transition-duration: 1s;

        .box-infos {
            width: 100%;
            text-align: center;

            .foto {
                width: 100%;
                position: relative;

                >img {
                    border: solid 7px $verdeClaro;
                    border-radius: 100%;
                    margin-bottom: 10px;
                    background: #fff;
                }

                .bt-foto {
                    position: absolute;
                    background: #fff;
                    border-radius: 100%;
                    border: solid 3px $verdeEscuro;
                    min-width: auto;
                    min-height: auto;
                    padding: 5px;
                    right: calc(50% - 65px);

                    &:hover {
                        background: $verdeClaro;

                        svg {
                            transform: rotateY(360deg);
                        }
                    }

                    svg {
                        font-size: 20px;
                        transform: rotateY(0deg);
                        @include expoOut();
                        transition-duration: 1.5s;
                    }
                }

                .bt-editar {
                    position: absolute;
                    color: $verdeClaro;
                    border-bottom: solid 1px $verdeClaro;
                    min-width: auto;
                    padding: 0 3px;
                    left: calc(50% + 130px);
                    border-radius: 0;
                    text-transform: none;
                    font-size: 13px;
                    font-weight: 400;
                }

                h3 {
                    color: $verdeClaro;
                    font-size: 21px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    margin-bottom: 15px;
                }
            }

            .box-points {
                background: #fff;
                color: $cinza;
                padding: 7px 20px;
                border-radius: 20px;
                margin-bottom: 15px;

                .esq {
                    h3 {
                        font-size: 35px;
                        font-weight: 500;
                        color: $verdeEscuro;
                        margin-top: -5px;
                        margin-bottom: -8px;
                        letter-spacing: 1px;
                    }

                    small {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .right {
                    border-left: solid 2px rgba($color: #000000, $alpha: 0.1);
                    margin: -7px 0;
                    padding-left: 15px;
                    margin-left: 20px;

                    li {
                        width: auto;

                        .MuiButtonBase-root {
                            border-bottom: solid 1px;
                            color: $verdeEscuro;
                            padding-bottom: 5px;
                            font-size: 25px;
                            border-radius: 0;
                            padding: 0;
                            text-transform: none;
                        }
                    }
                }
            }

            .box-contatos {
                width: 100%;
                font-weight: 300;
                font-size: 13px;
                letter-spacing: 1px;

                p {
                    margin: 0;

                    strong {
                        color: $verdeClaro;
                    }

                    svg {
                        font-size: 20px;
                        color: $verdeClaro;
                        position: relative;
                        top: 5px;
                    }
                }

                margin-bottom: 30px;
            }

            h4 {
                font-weight: 500;
                font-size: 20px;
                color: $verdeClaro;
                margin-bottom: 20px;
            }

            ol {
                margin: 0 auto;
                width: 100%;

                li {
                    margin: 0 10px;
                    display: block;
                    width: 55px;

                    svg {
                        width: 50px;
                        height: 50px;
                        background: rgba($color: #fff, $alpha: 0.1);
                        border-radius: 100%;

                        path {
                            fill: $verdeClaro;
                        }
                    }

                    small {
                        display: block;
                        font-size: 11px;
                        font-weight: 300;
                        letter-spacing: 1px;
                        padding-top: 5px;
                    }
                }
            }
        }

        .impulso-bayer {
            width: 100%;

            .bt-impulso {
                color: #fff;
                text-transform: none;
                font-size: 12px;

                span {
                    display: block;
                    font-weight: 300;
                }

                img {
                    width: 129px;
                }
            }
        }
    }

    .area-menu {
        position: absolute;
        background: #fff;
        width: 60%;
        height: 100%;
        right: -60%;
        top: 0;
        padding: 20px 70px;
        @include expoOut();
        transition-duration: 1s;

        >h2 {
            font-size: 31px;
            color: $verdeClaro;
            background: $verdeEscuro;
            text-transform: uppercase;
            padding: 10px 50px;
            font-weight: 500;
            line-height: 35px;
            margin-bottom: 10px;
            border-radius: 0 60px 0 60px;
            position: relative;
            margin-top: 20px;
            width: calc(100% - 20px);

            i {
                position: absolute;
                right: -35px;
                bottom: 0;
                font-size: 45px;
                font-style: normal;
            }
        }

        .nao-logado {
            h3 {
                font-size: 27px;
                font-weight: 500;
                margin-bottom: -15px;
                color: $verdeEscuro;
            }

            p {
                font-size: 15px;
                color: $verdeEscuro;

                .MuiButtonBase-root {
                    font-weight: 500;
                    color: $verdeEscuro;
                    border-bottom: solid 1px;
                    padding: 0;
                    border-radius: 0;
                    min-width: 0;
                }
            }
        }

        .logado {
            color: $verdeEscuro;

            >h3 {
                font-size: 26px;
                font-weight: 500;
                background: $verdeClaro;
                border-radius: 0 30px 0 30px;
                padding: 5px 30px;
                text-transform: uppercase;
                display: inline-block;
            }

            .box-tema {
                border: solid 3px $verdeClaro;
                border-radius: 30px 0 30px 0;
                padding: 20px;
                margin-bottom: 15px;

                .esq {
                    .box {
                        span {
                            font-size: 19px;
                            font-weight: 500;
                            text-transform: uppercase;
                        }

                        h4 {
                            font-size: 34px;
                            color: $cinza;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }
                }

                .dir {
                    border-left: solid 1px $verdeEscuro;
                    padding-left: 25px;
                    margin-left: 25px;

                    h4 {
                        font-size: 28px;
                        font-weight: 300;
                    }

                    .MuiButtonBase-root {
                        text-align: left;
                        text-transform: none;
                        line-height: 18px;
                        font-size: 18px;
                        font-weight: 400;
                        margin-top: 10px;

                        &:hover {
                            background: none;

                            .ico {
                                svg {
                                    transform: scale(1.2);
                                }
                            }
                        }

                        .ico {
                            color: #fff;
                            background: $dark;
                            padding: 5px;
                            border-radius: 100%;
                            margin-right: 15px;
                            width: 45px;
                            height: 45px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                width: 51px;
                                height: 51px;
                                border: solid 1px #a3d058;
                                border-radius: 100%;
                                transform: scale(1.05);
                                @include expoOut();
                            }
                        }
                    }
                }
            }

            .cards {
                padding-top: 10px;

                .card {
                    text-align: center;
                    border-radius: 0 30px 0 30px;
                    border: solid 3px $verdeEscuro;
                    margin-right: 10px;
                    position: relative;
                    min-width: 250px;
                    padding-top: 46px;

                    h6 {
                        background: $verdeEscuro;
                        color: $verdeClaro;
                        font-size: 11px;
                        text-transform: uppercase;
                        border-radius: 0 30px 0 30px;
                        padding: 10px 30px;
                        margin-right: -3px;
                        margin-top: -3px;
                        font-weight: 500;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        min-height: 46px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h2 {
                        font-size: 36px;
                        font-weight: 500;
                        padding: 2px 20px;
                    }

                    h3 {
                        color: $cinza;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .links {
            padding-top: 20px;
            padding-bottom: 25px;

            ul {
                li {
                    margin-right: 30px;

                    .MuiButtonBase-root {
                        padding: 0;
                        border-bottom: solid 1px $verdeEscuro;
                        color: $verdeEscuro;
                        min-width: auto;
                        line-height: 18px;
                        border-radius: 0;
                        font-size: 16px;
                        font-weight: 400;
                        @include easeOut();

                        &:hover {
                            background: none;
                            color: $verdeClaro;
                            border-bottom: solid 1px $verdeClaro;
                        }

                        &.sair {
                            text-transform: none;
                            font-size: 12px;
                            border: none;

                            svg {
                                color: $verdeClaro;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        .social-media {
            ul {
                padding-bottom: 10px;

                li {
                    margin-right: 20px;

                    .MuiButtonBase-root {
                        padding: 0;
                        min-width: auto;

                        &:hover {
                            background: none;

                            svg {
                                color: $verdeEscuro;
                            }
                        }

                        svg {
                            color: $verdeClaro;
                            font-size: 25px;
                            @include easeOut();
                        }
                    }
                }
            }

            .foot {
                p {
                    font-size: 13px;
                    color: $verdeEscuro;
                }

                ul {
                    li {
                        display: inline-block;

                        .MuiButtonBase-root {
                            padding: 0;
                            border-bottom: solid 1px $verdeEscuro;
                            color: $verdeEscuro;
                            min-width: auto;
                            line-height: 16px;
                            border-radius: 0;
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: unset;
                            @include easeOut();

                            &:hover {
                                background: none;
                                color: $verdeClaro;
                                border-bottom: solid 1px $verdeClaro;
                            }
                        }
                    }
                }
            }
        }
    }
}

body {

    &.page-chat-carol {
        a.optanon-show-settings {
            display: block;
        }
    }

    a.optanon-show-settings {
        z-index: 99999;
        position: fixed;
        color: #fff;
        bottom: 15px;
        right: 15px;
        cursor: pointer;
        display: none;
    }
}

body.loaded {
    header {

        &.flex {
            @include flex();
        }
    }

    .header-geral-2 {
        header {
            display: none;
        }

        .menu-foot {
            display: none;
        }
    }
}

.header-geral-2,
.header-geral {
    position: absolute;
    top: 0;
}

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;

    &.flex {
        display: none;
    }


    img {
        position: relative;
        z-index: 100;
        max-width: 180px;
        margin-left: 10px;
    }

    button.MuiButton-root {
        position: relative;
        left: 0;
        top: 0;
        margin-left: 20px;
    }


    .menu-ppp {
        cursor: pointer;
        border-radius: 100%;
        padding: 0;
        min-width: auto;
        min-height: auto;
        //border: solid 1px #fff;
        background: $verdeClaro;
        position: relative;
        z-index: 100;
        display: none;

        &.ativo {
            z-index: 99999;
            background: none;
            border: solid 2px $verdeEscuro;
            left: calc(100vw - 90px);
            position: absolute;
            top: 10px;
            display: block;

            b {
                color: $verdeEscuro;
            }

            span.ppp {
                span {
                    opacity: 0;

                    &:first-child {
                        left: 9px;
                        transform: scale(2);
                    }

                    &:last-child {
                        left: -9px;
                        transform: scale(2);
                    }
                }
            }

            span.x {
                width: 42px;
                height: 42px;
                border-radius: 100%;
                padding: 7px;
                position: absolute;
                left: 0;
                top: -2px;

                span {
                    display: block;
                    width: 100%;
                    height: 4px;
                    border-radius: 5px;
                    background: $verdeEscuro;
                    transition-duration: 1.5s;

                    &:first-child {
                        transform: rotate(45deg) translate(10px, 10px);
                    }

                    &:last-child {
                        transform: rotate(-45deg) translate(-7px, 7px);
                    }
                }
            }
        }

        &:hover {
            background: $verdeClaro;
            border-color: $verdeClaro;

            b {
                color: $verdeClaro;
                font-weight: 700;
            }

            span.ppp {
                span {
                    background: $verdeEscuro;

                    &:first-child {
                        left: 9px;
                        transform: scale(2);
                    }

                    &:last-child {
                        left: -9px;
                        transform: scale(2);
                    }
                }
            }
        }

        b {
            color: #fff;
            position: absolute;
            right: calc(100% + 10px);
            width: 160px;
            text-align: right;
            font-weight: 300;
            @include easeOut();
        }

        span.ppp {
            width: 42px;
            height: 42px;
            border-radius: 100%;
            padding: 10px;

            span {
                display: block;
                width: 5px;
                height: 5px;
                background: $verdeEscuro;
                border-radius: 100%;
                position: relative;
                left: 0;
                transform: scale(1);
                @include expoOut();
            }
        }

        span.x {
            width: 45px;
            height: 45px;
            border-radius: 100%;
            padding: 7px;
            position: absolute;
            left: 0;
            top: 0;

            span {
                display: block;
                width: 0;
                height: 4px;
                border-radius: 5px;
                background: $verdeEscuro;
                @include expoOut();
                transition-duration: 0.5s;

                &:first-child {
                    transform: rotate(45deg) translate(19px, -1px);
                }

                &:last-child {
                    transform: rotate(45deg) translate(16px, -3px);
                    transition-delay: 200ms;
                }
            }
        }
    }

    .bt-login {
        position: absolute;
        z-index: 100;
        right: 10px;
        top: 62px;
        color: #fff;
        background: none;
        padding: 0;
        font-weight: 300;
        display: none;
        @include easeOut();

        &:hover {
            color: $verdeClaro;
            background: none;

            b {
                font-weight: 700;
            }

            svg {
                padding: 4px;
            }
        }

        b {
            font-weight: 300;
        }

        svg {
            font-size: 30px;
            position: relative;
            top: -1px;
            margin-left: 10px;
            background: $verdeClaro;
            color: $verdeEscuro;
            border-radius: 100%;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            @include expoOut();
            transition-duration: 1s;
        }
    }

    .bt.sound {
        z-index: 9999;
        color: $verdeClaro;
        font-size: 14px;
        pointer-events: auto;
        color: #fff;
        background: none;
        padding: 0;
        font-weight: 300;
        min-width: auto;
        min-height: auto;
        margin: 0 0 0 20px;
        @include easeOut();

        &:hover {
            color: $verdeClaro;
            background: none;

            b {
                font-weight: 700;
            }

            svg {
                padding: 4px;
            }
        }

        b {
            font-weight: 300;
        }

        svg {
            margin-left: 10px;
            font-size: 30px;
            position: relative;
            top: -1px;
            background: $verdeClaro;
            color: $verdeEscuro;
            border-radius: 100%;
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 42px;
            height: 42px;
            @include expoOut();
            transition-duration: 1s;
        }
    }

    .card-atual-0 {
        .card-live-agora.card-0 {
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 10px 15px;
        }
    }

    .card-atual-1 {
        .card-live-agora.card-1 {
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 10px 15px;
        }
    }

    .card-atual-2 {
        .card-live-agora.card-2 {
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 10px 15px;
        }
    }

    .card-live-agora {
        position: relative;
        z-index: 99999;
        margin-left: 20px;
        background: $dark;
        padding: 0;
        padding-right: 70px;
        margin-right: -10px;
        opacity: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        @include easeOut();

        h4 {
            font-weight: 400;
            text-transform: uppercase;
            color: $verdeClaro;
            font-size: 14px;
            margin-bottom: 5px;
        }

        h2 {
            color: #fff;
            text-transform: uppercase;
            font-weight: 500;
        }

        .bt-assistir {
            color: #fff;
            margin: 0;
            padding-left: 0;
            font-weight: 300;
            text-transform: none;
            @include easeOut();

            &:hover {
                color: $verdeClaro;

                svg {
                    background: $verdeClaro;
                    color: $dark;
                }
            }

            svg {
                background: #fff;
                color: $verdeEscuro;
                border-radius: 100%;
                margin-right: 7px;
                @include easeOut();
            }
        }
    }

    .box-ajuda {
        width: 100%;
        padding-top: 15px;

        .bt-ajuda {
            position: relative;
            z-index: 99999;
            text-transform: none;
            font-weight: 400;
            font-size: 14px;
            width: auto;
            color: $verdeEscuro;
            background: rgba($color: #fff, $alpha: 0.9);
            border-radius: 100px;
            padding: 0 5px 0 15px;
            @include easeOut();
            display: none;

            &:hover {
                color: $verdeEscuro;
                background: $verdeClaro;
            }

            svg {
                margin-left: 10px;
                font-size: 23px;
            }
        }
    }
}

.MuiButton-containedPrimary.bt-menu {
    position: absolute;
    left: 15px;
    bottom: 15px;
    z-index: 999999;
    padding: 5px;
    min-height: auto;
    min-width: auto;
    width: 30px;
}

div#demo-popup-menu {
    z-index: 9999991 !important;
}

.show-powered {
    &.home-superior {
        header .bt-ajuda {
            //display: none;
        }
    }

    header .bt-ajuda {
        display: flex;
    }
}


@media (max-width: 1024px) {
    nav .area-menu {
        width: 55%;
        right: -55%;
    }

    nav .info-us {
        width: 45%;
        left: -45%;
    }

    header .menu-ppp b {
        display: none;
    }

    header .bt-login {

        b {
            display: none;
        }
    }
}

@media (max-width: 1024px) {

    nav {
        overflow-y: auto;
    }

    nav .area-menu {
        height: auto;
        min-height: 50%;
        padding: 20px;
        position: relative;
        width: 100%;
    }

    nav .info-us {
        height: auto;
        min-height: 50%;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 15px;
        align-items: flex-start;
        padding-top: 75px;
        width: 100%;
    }

    nav .info-us .box-infos .box-points {
        flex-wrap: wrap;
        width: 100%;
    }

    nav .info-us .box-infos .box-points .right svg {
        font-size: 24px;
        margin: 0 0;
    }

    nav .info-us .box-infos .box-points .right {
        border-left: none;
        margin: 7px 0;
        padding-left: 0;
        margin-left: 0;
        padding-top: 10px;
        border-top: solid 2px rgba(0, 0, 0, 0.1);
    }

    nav .info-us .box-infos .box-points .esq h3 {
        font-size: 30px;
    }

    nav .info-us .box-infos .foto .bt-editar {
        left: auto;
        right: 0;
    }

    .info-us>img {
        position: relative;
        bottom: 0;
        left: 0;
        margin-top: 70px;
    }

    nav .area-menu .sair {
        position: relative;
        right: 0;
        bottom: 0;
    }

    nav .area-menu h2 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        padding: 10px 18px;
        border-radius: 0 30px 0 30px;

        br {
            display: none;
        }
    }

    nav .area-menu .logado>h3 {
        font-size: 18px;
    }

    nav .area-menu .logado .box-tema .esq .box h4 {
        font-size: 24px;
    }

    nav .area-menu .logado .box-tema .dir h4 {
        font-size: 20px;
    }

    nav .area-menu .logado .box-tema .dir .MuiButtonBase-root {
        font-size: 14px;
    }

    nav .info-us .box-infos .box-points .right li .MuiButtonBase-root {
        font-size: 16px;
    }

    nav .info-us .box-infos .foto h3 {
        font-size: 16px;
    }

    nav .not-logged {
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
    }

    nav .not-logged .MuiButton-root {
        font-size: 14px;
        padding: 5px 18px;
    }

    nav .not-logged .MuiButton-root.cad {
        margin-left: 10px;
    }

    nav .area-menu .logado .box-tema .dir {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        border-top: solid 1px $dark;
        margin-top: 10px;
        padding-top: 10px;
        width: 100%;
    }

    nav .area-menu .logado .cards .card {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
        padding-top: 60px;
    }

    header img {
        z-index: 9999;
        max-width: 90px;
    }

    header .card-live-agora {
        padding: 10px;
        padding-right: 10px;
        padding-bottom: 0;
        margin-left: 10px;
    }

    header .card-live-agora h4 {
        font-size: 10px;
    }

    header .card-live-agora h2 {
        font-size: 14px;
    }

    body .main-menu-hud .menu-foot {
        top: calc(100% - 60px);
    }

    body .main-menu-hud .menu-foot ul li .bt {
        padding: 0 10px;
        margin: 0 5px;
        font-size: 10px;
    }

    body section.minhas-tarefas .itens .item .col {
        font-size: 12px;
        border-radius: 7px;
    }

    body header .bt.sound b {
        display: none;
    }

    header button.MuiButton-root {
        margin-left: 0;

        &.menu-ppp {
            border: solid 2px #fff;
            top: 10px;
            right: 20px;
            left: auto;
            background: #fff;
        }

    }

    header .bt.sound {
        margin-left: 0;
    }

    header .box-ajuda .bt-ajuda {
        font-size: 12px;
        width: auto;
        margin-top: -7px;
    }

    nav .area-menu .links ul {
        flex-wrap: wrap;
        text-align: center;
    }

    nav .area-menu .links ul li {
        margin-right: 0;
        width: 100%;
        margin-bottom: 15px;
    }

    body.home-superior {
        header .bt-ajuda {
            display: none;
        }
    }
}

@media (max-width: 1024px) {
    header {

        .bt.sound {
            position: absolute;
            top: 50px;
        }
    }

    .show-rural {
        header {
            z-index: 9;
        }
    }

    a.optanon-show-settings {
        bottom: auto;
        right: 15px;
        top: 15px;
    }
}