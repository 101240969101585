.panolens-container {
    position: fixed;
    top: 0;
    left: 0;
}

.seta-frente {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 50px);
    z-index: 9999;
}