@import "../../variables";

.loading-component {
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.7);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 9999999;

    &.ativo {
        opacity: 1;
        visibility: visible;
    }

    .MuiCircularProgress-colorPrimary {
        color: $verde;
    }

    .MuiCircularProgress-root {
        width: 30px !important;
        height: 30px !important;
    }
}