@import "../../variables";

.MuiSnackbar-root {
    z-index: 9999;
}

.MuiSnackbarContent-root {
    z-index: 99999;

    .MuiSnackbarContent-message {
        font-size: 12px;
    }

    .MuiButtonBase-root {
        font-size: 12px;
        color: #fff;
    }
}

.snack {
}