@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

$verdeClaro: #89fa4f;
$verdeEscuro: #003851;
$cinza: #979ea8;

$amarelo: #f8b900;
$dark: #003851;
$azul: #83d1eb;

section.minhas-tarefas {
    background: #fff;
    color: $verdeEscuro;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    padding: 20px 35px;
    padding-top: 45px;
    opacity: 0;
    visibility: hidden;
    @include easeOut();

    &.ativo {
        opacity: 1;
        visibility: visible;
    }

    .filtro {
        border-bottom: solid 3px $verdeEscuro;
        margin-bottom: 20px;

        h2 {
            font-size: 28px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .MuiFormControl-root {
            margin-bottom: 10px;
        }
    }

    .itens {
        height: calc(100% - 120px);
        overflow-y: auto;

        .item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            min-width: 500px;

            .col {
                border: solid 2px $verdeEscuro;
                border-radius: 100px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;

                &.tarefa_desc {
                    width: 15%;
                }

                &.tarefa {
                    width: calc(70% - 20px - 162px - 30px);
                }

                &.status {
                    width: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.ativo {
                        &::after {
                            background: $verdeClaro;
                            content: '';
                            width: calc(100% - 6px);
                            height: calc(100% - 6px);
                            border-radius: 100px;
                            display: block;
                            position: absolute;
                        }
                    }
                }

                &.data {
                    width: 15%;
                }
            }

            .MuiButtonBase-root {
                width: 162px;
                pointer-events: none;
                margin: 0;

                &.ativo {
                    pointer-events: unset;

                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .bt-fechar {
        position: absolute;
        top: 15px;
        right: 30px;
        left: auto;
        color: $dark;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;
        z-index: 9;

        &:hover {
            svg {
                color: $verdeClaro;
            }
        }

        svg {
            font-size: 40px;
            color: $verdeEscuro;
            @include easeOut();
        }
    }
}

.modal-cupom {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    opacity: 0;
    padding-top: 50px;
    visibility: hidden;
    @include expoOut();

    &.ativo {
        visibility: visible;
        opacity: 1;
        padding-top: 0px;
    }

    .box {
        background: #fff;
        border: solid 3px $verdeEscuro;
        border-radius: 0 30px 0 30px;
        position: relative;
        color: $verdeEscuro;

        h2 {
            background: $verdeEscuro;
            border-radius: 0 30px 0 30px;
            text-align: center;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 28px;
            position: relative;
            top: -2px;
            width: calc(100% + 2px);

            img {
                margin-left: 2px;
                position: relative;
                top: 2px;
            }
        }

        .cols {
            padding: 10px;
            text-align: center;

            .col {
                padding-bottom: 20px;

                &.esq {
                    border-right: solid 2px $verdeClaro;
                    margin-right: 15px;
                }

                h3 {
                    font-size: 63px;
                    font-weight: 500;
                    padding: 0 30px;

                    span {
                        font-size: 16px;
                        display: block;
                        margin-top: -10px;
                        color: $cinza;
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                }

                h4 {
                    display: block;
                    font-size: 31px;
                    border: solid 3px $verdeClaro;
                    padding: 5px 20px;
                    border-radius: 7px;
                    font-weight: 500;
                }
            }
        }

        .bt-fechar {
            position: absolute;
            top: 5px;
            right: 15px;
            left: auto;
            color: $dark;
            padding: 0;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
            background: none;
            z-index: 9;

            &:hover {
                svg {
                    color: #fff;
                }
            }

            svg {
                font-size: 40px;
                color: $verdeClaro;
                @include easeOut();
            }
        }
    }
}

.MuiPopover-root {
    z-index: 99999 !important;
}

@media (max-width: 1024px) {
    .modal-cupom .box .cols {
        flex-wrap: wrap;
    }

    .modal-cupom .box .cols .col {
        padding-bottom: 20px;
        width: 100%;
        justify-content: center;
    }

    .modal-cupom .box .cols .col.esq {
        border-right: none;
        border-bottom: solid 2px #89fa4f;
        margin-right: 15px;
    }
}