@import '../../variables.scss';

.main-menu-hud {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .menu-foot {
        position: absolute;
        z-index: 99999;
        width: 100%;
        top: calc(100% - 80px);

        ul {
            text-align: center;

            li {
                display: inline-block;

                .bt {
                    color: $verdeClaro;
                    background: $verdeEscuro;
                    padding: 0 20px;
                    font-size: 14px;
                    margin: 0 10px;
                    text-transform: uppercase;
                    font-weight: 300;

                    &:hover {
                        background: $verdeClaro;
                        color: $verdeEscuro;
                    }
                }
            }
        }
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.main-menu-hud,
.fullscreen-hud {
    z-index: 999;

    .circle-button {
        stroke: $verdeClaro;
        stroke-width: 0;
        fill: $verdeClaro;
        animation: animaCircle 1s steps(50) infinite alternate;

        &:hover {
            stroke: $verdeClaro;
        }

        &.blur {
            stroke-width: 0;
            fill: $verdeClaro;
            opacity: 0.3;

            &:hover {
                stroke: $verdeClaro;
            }
        }

        &.borda {
            opacity: 0.3;
            animation: animaBorda 1s steps(50) infinite alternate;

            &:hover {
                stroke: $verdeClaro;
            }
        }

        @keyframes animaBorda {
            from {
                stroke-width: 0;
            }

            to {
                stroke-width: 30px;
            }
        }

        @keyframes animaCircle {
            from {
                stroke-width: 0;
            }

            to {
                stroke-width: 10px;
            }
        }
    }

    .render-area .layer {
        overflow: hidden;
    }
}

.layer {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

svg {
    .legenda-mobile {
        fill: #fff;
        font-size: 20px;
        display: none;
    }

    .legenda-mobile-bg {
        fill: rgba(0, 0, 0, 0.5) !important;
        border-radius: 5px !important;
        stroke: $verdeClaro !important;
        stroke-width: 2px !important;
        display: none;
    }
}

.fot-logo {
    position: absolute;
    bottom: 10px;
    right: 0;
    padding-right: 10px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 0 10px #fff;
    font-size: 10px;
    color: #00354f;
    z-index: 999;

    .box {
        background: hsla(0,0%,100%,.9);
        padding: 2px 10px;
        border-radius: 100px;

        img {
            margin-left: 15px;
            width: 50px;
        }

        a {
            color: #00354f;
        }
    }
}

@media (max-width: 1024px) {

    svg .legenda-mobile,
    svg .legenda-mobile-bg {
        display: block;
    }

    body .main-menu-hud {
        z-index: 9999;
    }
}