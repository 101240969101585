@media (max-width: 1024px) {
    body.page-totem {

        section.totem {
            background: url(../../assets/panolens/risco-totem.jpg) no-repeat top center/cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;


            >.layer {
                z-index: 9999;
            }
        }
    }

    .page-totem .cards {
        display: none;
    }
}