$verde: #b1fa5f;
$dark: #003851;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0, 0, 0.38, 1);
    -moz-transition: all 500ms cubic-bezier(0, 0, 0.38, 1);
    -o-transition: all 500ms cubic-bezier(0, 0, 0.38, 1);
    transition: all 500ms cubic-bezier(0, 0, 0.38, 1);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
}

section.agenda {
    // background: url(../../assets/agenda/bg-agenda.jpg) no-repeat center left / cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 99999;

    &.animation-true {
        .modal-agenda.active {
            background: rgba($color: #000000, $alpha: 0.8);

            .bt-fechar {
                opacity: 1;
                visibility: visible;
            }

            .box-agenda {
                top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .modal-agenda {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0);
        z-index: 9;
        overflow: hidden;
        @include expoOut();

        &.active {
            background: rgba($color: #000000, $alpha: 0);
            z-index: 9999;

            .bt-fechar {
                opacity: 0;
                visibility: hidden;
            }

            .box-agenda {
                top: 200px;
                opacity: 0;
            }
        }

        .box-agenda {
            background: url(../../assets/agenda/bg-box-agenda.jpg) no-repeat center center / cover;
            max-width: 1000px;
            width: 100%;
            padding: 30px 50px;
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.5);
            top: 200px;
            opacity: 0;
            visibility: visible;
            position: relative;
            @include expoOut();
            transition-duration: 1.2s;

            .area-table {
                height: calc(100vh - 155px);
            }

            .filtro {
                padding-bottom: 20px;

                h1 {
                    color: $verde;
                    font-weight: 300;
                    text-transform: uppercase;
                    font-size: 40px;
                    letter-spacing: 2px;

                    small {
                        display: block;
                        font-size: 12px;
                    }

                    img {
                        margin-right: 15px;
                    }
                }

                h3 {
                    color: $dark;
                    font-weight: 300;
                    text-align: right;
                    font-size: 16px;
                    padding: 10px 0;
                    padding-left: 50px;
                    padding-right: 10px;
                    background: url(../../assets/bg-destaque-evento.png) no-repeat center center / 100%;

                    a {
                        display: block;
                        color: $dark;
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }

                .MuiFormLabel-root,
                .MuiInputBase-root,
                .MuiSelect-icon {
                    color: $verde;
                }

                .MuiInput-underline:before {
                    border-color: $verde;
                }

                .MuiInput-underline:after {
                    border-color: #fff;
                }
            }

            .MuiTableContainer-root {
                //max-height: 440px;

                .MuiTableHead-root {
                    .MuiTableCell-head {
                        background: no-repeat;
                        color: $verde;
                        border-top: solid 1px $verde;
                        border-bottom: solid 1px $verde;
                        background: #263131;
                    }
                }

                .MuiTableBody-root {
                    .MuiTableRow-root {
                        opacity: 0.5;

                        &.online {
                            opacity: 1;
                            cursor: pointer;
                            background: rgba($color: #fff, $alpha: 0);

                            &:hover {
                                background: rgba($color: #fff, $alpha: 0.3);
                            }
                        }

                        .MuiTableCell-body {
                            color: #fff;
                            border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);

                            small {
                                display: block;
                                opacity: 0.5;
                                font-weight: 300;
                            }
                        }
                    }
                }

                .status {
                    pointer-events: none;
                    min-width: 70px;

                    &.on {
                        b {
                            background: $verde;
                            animation: luzAnima 0.3s steps(10) infinite alternate;
                        }
                    }

                    b {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: #f00;
                        border-radius: 100%;
                        margin-right: 5px;
                    }

                    @keyframes luzAnima {
                        from {
                            box-shadow: 0 0 0 rgba($color: $verde, $alpha: 0);
                        }

                        to {
                            box-shadow: 0 0 15px rgba($color: $verde, $alpha: 1);
                        }
                    }
                }
            }
        }

        .bt-fechar {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $verde;
            padding: 0;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
            background: none;
            opacity: 0;
            visibility: hidden;
            @include easeOut();

            &:hover {
                svg {
                    color: #fff;
                }
            }

            svg {
                font-size: 40px;
                @include easeOut();
            }
        }
    }
}

@media (max-width: 1024px) {
    section.agenda .modal-agenda .box-agenda .filtro {
        flex-wrap: wrap;
    }

    section.agenda .modal-agenda .box-agenda .filtro h1 {
        font-size: 30px;
    }

    section.agenda .modal-agenda .box-agenda .filtro h1 img {
        width: 30px;
    }

    section.agenda .modal-agenda .box-agenda .MuiTableContainer-root {
        //max-height: 200px;
        overflow-x: unset;
    }

    section.agenda .modal-agenda .box-agenda {
        max-width: calc(100% - 10px);
        padding: 30px 30px;
    }

    section.agenda .modal-agenda .box-agenda .filtro h3 {
        padding-left: 20px;
        padding-right: 20px;
        background-size: cover;
    }

    section.agenda .modal-agenda .box-agenda .area-table {
        height: calc(100vh - 300px);
        min-height: 80vh;
    }

    section.agenda .modal-agenda {
        overflow-y: auto;
        align-items: flex-start;
    }

    .msg-hover {
        display: none;
    }
}
