$amarelo: #f8b900;
$dark: #003851;
$azul: #83d1eb;
$verde: #89fa4f;

article.modal {
    h2 {
        font-weight: 500;
    }

    p {
        font-weight: 300;

        span {
            font-weight: 500;
        }
    }

    .bts {
        .bt {
            background: $dark;
            color: #fff;
            border-radius: 100px;
            width: calc(50% - 10px);
            position: relative;
            font-size: 17px;
            font-weight: normal;
            cursor: pointer;

            &:hover {
                background: $verde;
                color: $dark;
            }

            &.cad {
                margin-left: 20px;
                background: rgba($color: $dark, $alpha: 0);
                border: solid 1px $dark;
                color: $dark;

                &:hover {
                    background: $dark;
                    color: #fff;
                }
            }
        }
    }
}