@media (max-width: 1024px) {
    body.page-inovacao {
        section.inovacao {
            background: url(../../assets/panolens/inovacao.jpg) no-repeat 55% center/cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}