$dark: #003851;
$verde: #89fa4f;
$light: #e9f1f3;
$cinza: #979ea8;

$riscoDark: rgb(68, 50, 71);
$riscoLight: rgb(122, 82, 129);

$produtividadeDark: rgb(0, 68, 34);
$produtividadeLight: rgb(3, 97, 50);

$negocioDark: rgb(16, 56, 79);
$negocioLight: rgb(21, 90, 129);

$inovacaoDark: rgb(137, 211, 41);
$inovacaoLight: rgb(172, 236, 88);

$colorDark: var(--color-dark);
$colorLight: var(--color-light);

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

section.more-services {
    min-height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 99999;

    &.animation-true {
        .area-filter {
            left: 0;
        }

        .area-text {
            right: 0;

            .card-services {
                top: 0;
                opacity: 1;
            }
        }
    }

    img.logo {
        width: auto;
        position: absolute;
        top: 0;
        left: 15px;
        width: 160px;
    }

    .area-filter {
        width: 35%;
        height: 100%;
        background: $dark;
        top: 0;
        left: -50%;
        position: absolute;
        padding: 20px 20px;
        overflow-y: auto;
        overflow-x: hidden;
        @include expoOut();
        transition-duration: 1s;

        img {
            max-width: 170px;
        }

        h1 {
            font-size: 40px;
            color: $verde;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 40px;
        }

        .box-points {
            background: #fff;
            color: $cinza;
            padding: 7px 20px;
            border-radius: 20px;
            margin-bottom: 15px;

            .esq {
                text-align: center;
                width: 45%;

                h3 {
                    font-size: 35px;
                    font-weight: 500;
                    color: $dark;
                    margin-top: -5px;
                    margin-bottom: -8px;
                    letter-spacing: 1px;
                }

                small {
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .right {
                border-left: solid 2px rgba($color: #000000, $alpha: 0.1);
                margin: -7px 0;
                padding-left: 15px;
                margin-left: 20px;
                width: 55%;

                svg {
                    color: #f8b900;
                    font-size: 30px;
                    margin: 0 3px;

                    &.gray {
                        color: $cinza;
                    }
                }
            }
        }

        .filter {
            width: 100%;

            .filters {
                .box-filter {
                    margin-bottom: 40px;

                    h5 {
                        color: $verde;
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 1px;
                    }

                    .title {
                        margin-bottom: 15px;
                        margin-top: 20px;

                        .MuiButton-root {
                            left: 0;
                            background: none;
                            color: rgba($color: #fff, $alpha: 0.5);
                            font-size: 10px;
                            padding: 0;
                            min-width: 80px;
                            min-height: auto;
                            border-bottom: solid 1px;
                            border-radius: 0;
                            line-height: 14px;
                            text-transform: none;
                        }
                    }

                    .MuiSlider-root {
                        margin-top: 10px;
                        margin-bottom: 10px;
                        width: calc(100% - 30px);
                        position: relative;
                        left: 15px;

                        .MuiSlider-markLabel {
                            top: 30px;
                            color: #fff;
                            font-size: 9px;
                        }

                        .MuiSlider-markLabelActive {
                            color: $verde;
                        }

                        .MuiSlider-mark {
                            width: 1px;
                            height: 10px;
                            background-color: rgba($color:#fff, $alpha: 0.3);
                        }

                        .MuiSlider-rail {
                            height: 4px;
                            margin-top: -1px;
                            opacity: 1;
                            background: #fff;
                        }

                        .MuiSlider-track {
                            height: 5px;
                            margin-top: -10px;
                            background: none;

                            &::after {
                                content: "";
                                position: absolute;
                                width: calc(100% - 14px);
                                height: 100%;
                                left: 7px;
                                background: rgba($color: $verde, $alpha: 0.5);
                            }
                        }

                        .MuiSlider-thumb {
                            width: 4px;
                            height: 25px;
                            margin-top: -11px;
                            margin-left: -2px;
                            border-radius: 20px;
                            background: $verde;
                            display: flex;
                            justify-content: center;

                            .MuiSlider-valueLabel {
                                left: auto;
                                //transform: scale(1);
                                top: -15px;

                                >span {
                                    width: auto;
                                    height: auto;
                                    padding: 3px 10px;
                                    font-size: 12px;
                                    transform: rotate(0deg);
                                    border-radius: 100px;
                                    background: #fff;

                                    >span {
                                        color: $dark;
                                        transform: rotate(0deg);
                                        font-size: 11px;
                                    }
                                }
                            }
                        }
                    }

                    .categories {
                        width: 100%;

                        .MuiButton-root {
                            background: $cinza;
                            color: $dark;
                            border-radius: 100px;
                            position: relative;
                            text-transform: none;
                            position: relative;
                            left: 0;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 3px 10px;

                            svg {
                                position: absolute;
                                left: 5px;
                                top: 2px;
                                font-size: 220%;
                                transform: rotate(0deg);
                                @include expoOut();
                                transition-duration: 2s;
                            }

                            &:hover {
                                background: #fff;
                                color: $dark;
                            }

                            &.active {
                                background-color: $verde;
                            }
                        }
                    }

                    p {
                        margin: 0;

                        small {
                            color: #fff;
                            font-size: 10px;
                            text-align: center;
                            font-weight: 300;
                            letter-spacing: 1px;
                            display: inline-block;
                            padding-top: 5px;
                        }
                    }

                }
            }
        }

        .powered {
            color: $verde;
            font-size: 10px;
            font-weight: 300;

            img {
                margin-left: 7px;
                position: relative;
                top: 8px;
                max-width: 105px;
            }
        }

    }

    .area-text {
        background: $light;
        width: 65%;
        height: 100%;
        padding: 20px 20px;
        overflow-y: auto;
        padding-top: 50px;
        position: absolute;
        right: -100%;
        @include expoOut();
        transition-duration: 1s;

        .lista-suspensa {
            width: 100%;
            position: sticky;
            top: 0;
            transform: translateY(-50px);
            z-index: 9;
            background: $light;
            padding-top: 25px;
            margin-bottom: -25px;

            .MuiFormControl-root {
                width: 50%;
            }
        }

        .card-services {
            position: relative;
            top: 100px;
            @include expoOut();
            opacity: 0;
            transition-duration: 1s;

            &:nth-child(1) {
                transition-delay: 200ms;
            }

            &:nth-child(2) {
                transition-delay: 300ms;
            }

            &:nth-child(3) {
                transition-delay: 400ms;
            }

            &:nth-child(4) {
                transition-delay: 500ms;
            }

            &:nth-child(5) {
                transition-delay: 600ms;
            }

            &:nth-child(6) {
                transition-delay: 700ms;
            }

            &:nth-child(7) {
                transition-delay: 800ms;
            }

            &:nth-child(8) {
                transition-delay: 900ms;
            }
        }
    }

    .bt-fechar {
        position: absolute;
        top: 5px;
        right: 5px;
        color: $dark;
        padding: 0;
        min-width: auto;
        min-height: auto;
        border-radius: 100%;
        background: none;
        z-index: 9;

        &:hover {
            svg {
                color: $verde;
            }
        }

        svg {
            font-size: 40px;
            @include easeOut();
        }
    }

    .modal-more-services {
        position: fixed;
        z-index: 999;
        top: 200px;
        left: 0;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @include expoOut();

        .bt-fechar {
            position: absolute;
            top: 5px;
            right: 5px;
            color: rgba($color: #000000, $alpha: 0.2);
            padding: 0;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
            background: none;

            &:hover {
                svg {
                    color: $colorDark;
                }
            }

            &.produtividade {
                &:hover {
                    svg {
                        color: $produtividadeDark;
                    }
                }
            }

            &.risco {
                &:hover {
                    svg {
                        color: $riscoDark;
                    }
                }
            }

            &.negocios {
                &:hover {
                    svg {
                        color: $negocioDark;
                    }
                }
            }

            &.inovacao,
            &.fieldview {
                &:hover {
                    svg {
                        color: $inovacaoDark;
                    }
                }
            }

            svg {
                font-size: 40px;
                @include easeOut();
            }
        }

        &.active {
            opacity: 1;
            visibility: visible;
            top: 0;
        }

        .bt-fechar {
            position: absolute;
            top: 5px;
            right: 5px;
            color: rgba($color: #000000, $alpha: 0.2);
            padding: 0;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
            background: none;

            &:hover {
                svg {
                    color: $colorDark;
                }
            }

            svg {
                font-size: 40px;
                @include easeOut();
            }
        }
    }
}

.MuiList-root {
    .MuiListItem-root {
        &.produtividade {
            background: $produtividadeDark;
            color: #fff;

            &:hover {
                background: $verde;
                color: $dark;
            }
        }

        &.risco {
            background: $riscoDark;
            color: #fff;

            &:hover {
                background: $verde;
                color: $dark;
            }
        }

        &.negocios {
            background: $negocioDark;
            color: #fff;

            &:hover {
                background: $verde;
                color: $dark;
            }
        }

        &.inovacao,
        &.fieldview {
            background: $inovacaoDark;
            color: #fff;

            &:hover {
                background: $verde;
                color: $dark;
            }
        }
    }
}

@media (max-width: 1024px) {

    section.more-services {
        height: 100%;
        overflow-y: auto;
        flex-wrap: wrap;
    }

    section.more-services .card-services {
        width: calc(50% - 10px);
        margin: 10px 5px;
    }

    section.more-services .area-filter {
        width: 100%;
        height: auto;
        position: relative;
    }

    section.more-services .area-text {
        width: 100%;
        height: auto;
        position: relative;
    }

    section.more-services .area-filter h1 {
        font-size: 4%;
        line-height: 4%;
        padding-left: 0;
        padding-top: 15px;
    }

    section.more-services .area-text {
        padding: 20px 5px;
        padding-top: 50px;
    }

    section.more-services .area-text .box-icon img {
        width: 75px;
    }

    section.more-services .area-text .box-icon .txt h4 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    section.more-services .area-text .box-icon .txt p {
        font-size: 16px;
    }

    section.more-services .area-filter .box-points {
        flex-wrap: wrap;
    }

    section.more-services .area-filter .box-points .right svg {
        font-size: 24px;
        margin: 0 0;
    }

    section.more-services .area-filter .box-points .esq {
        width: 100%;
    }

    section.more-services .area-filter .box-points .right {
        border-left: none;
        margin: 7px 0;
        padding-left: 0;
        margin-left: 0;
        padding-top: 10px;
        border-top: solid 2px rgba(0, 0, 0, 0.1);
        width: 100%;
    }

    section.more-services .area-filter .box-points .esq h3 {
        font-size: 30px;
    }

    section.more-services .area-filter .filter .filters .box-filter .MuiSlider-root .MuiSlider-markLabel {
        top: 40px;
    }

    section.more-services .area-filter .filter .filters .box-filter .categories .MuiButton-root {
        margin-bottom: 10px;
    }

    section.more-services .area-filter .powered {
        padding-top: 15px;
    }

    section.more-services .area-filter .filter .filters .box-filter .categories {
        justify-content: flex-start;
    }

    section.more-services .area-filter .filter .filters .box-filter .categories .MuiButton-root {
        margin-right: 15px;
    }

    section.more-services .modal-more-services .bt-fechar,
    section.more-services .bt-fechar {
        color: $verde;
    }
}