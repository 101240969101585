.panolens-container {
    position: fixed;
    top: 0;
    left: 0;
}

.panolens-infospot {
    opacity: 0;
}

.panolens-container span {
    display: none;
}

.MuiButtonBase-root.bt-oculus {
    position: absolute;
    right: 15px;
    bottom: 70px;
    z-index: 99999;
    font-size: 14px;
    padding: 0 5px;
    //opacity: 0.5;

    img {
        width: 45px;
    }

    img.pano-off {
        display: block;
    }

    img.pano-on {
        display: none;
    }

    &.ativo-true {
        img.pano-off {
            display: none;
        }

        img.pano-on {
            display: block;
        }
    }
}

.MuiButtonBase-root.bt-full {
    position: absolute;
    right: 28px;
    bottom: 122px;
    z-index: 99999;
    font-size: 14px;
    padding: 5px;
    min-width: 0;

    svg {
        margin: 0;
    }
}