@import '../../variables.scss';

.main-menu-hud {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .menu-foot {
        position: absolute;
        z-index: 99999;
        width: 100%;
        top: calc(100% - 80px);

        ul {
            text-align: center;

            li {
                display: inline-block;

                .bt {
                    color: $verdeClaro;
                    background: $verdeEscuro;
                    padding: 0 20px;
                    font-size: 14px;
                    margin: 0 10px;
                    text-transform: uppercase;
                    font-weight: 300;

                    &:hover {
                        background: $verdeClaro;
                        color: $verdeEscuro;
                    }
                }
            }
        }
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.hamburger {
    display: none;
}

.main-menu-hud,
.fullscreen-hud {
    z-index: 999;

    .circle-button {
        stroke: $verdeClaro;
        stroke-width: 0;
        fill: $verdeClaro;
        animation: animaCircle 1s steps(50) infinite alternate;

        &:hover {
            stroke: $verdeClaro;
        }

        &.blur {
            stroke-width: 0;
            fill: $verdeClaro;
            opacity: 0.3;

            &:hover {
                stroke: $verdeClaro;
            }
        }

        &.borda {
            opacity: 0.3;
            animation: animaBorda 1s steps(50) infinite alternate;

            &:hover {
                stroke: $verdeClaro;
            }
        }

        @keyframes animaBorda {
            from {
                stroke-width: 0;
            }

            to {
                stroke-width: 30px;
            }
        }

        @keyframes animaCircle {
            from {
                stroke-width: 0;
            }

            to {
                stroke-width: 10px;
            }
        }
    }

    .render-area .layer {
        overflow: hidden;
    }
}

.layer {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
}

svg {
    .legenda-mobile {
        fill: #fff;
        font-size: 20px;
        display: none;
    }

    .legenda-mobile-bg {
        fill: rgba(0, 0, 0, 0.5) !important;
        border-radius: 5px !important;
        stroke: $verdeClaro !important;
        stroke-width: 2px !important;
        display: none;
    }
}

.panolens-container {
    opacity: 0;
    z-index: 1;
}

.piso-superior-mobile {
    display: none;
}

.bt.piso-superior-mobile {
    display: none;
}

@media (max-width: 1024px) {

    .ce-container .render-area {
        z-index: 0;
    }

    svg .legenda-mobile,
    svg .legenda-mobile-bg {
        display: block;
    }

    .menu-foot {
        top: auto;
        bottom: 0;
        z-index: 99999;

        ul {
            display: none;

            &.burger-true {
                display: block;
            }

            li {
                display: block;

                .bt {
                    border-radius: 0;
                    width: 100%;
                    margin: 0;
                    padding: 10px;

                    &:hover {}
                }
            }
        }

        .hamburger {
            display: block;
            position: absolute;
            right: 15px;
            bottom: 15px;
            padding: 0;
            opacity: 1;

            &.is-active {
                bottom: 235px;
            }

            &:hover {
                opacity: 1;
            }

            .hamburger-inner,
            .hamburger-inner:after,
            .hamburger-inner:before {
                background-color: #fff;
            }
        }
    }

    .home-superior .menu-foot .hamburger.is-active {
        bottom: 150px;
    }

    body.home-inferior {
        .panolens-container.inferior {
            opacity: 1;
            z-index: 999;
        }
    }

    body.home-superior {
        .panolens-container.superior {
            opacity: 1;
            z-index: 999;
        }
    }

    body.home-reunioes {
        .panolens-container.reunioes {
            opacity: 1;
            z-index: 999;
        }
    }

    body.home-risco {
        .panolens-container.risco {
            opacity: 1;
            z-index: 999;
        }
    }
}

@media (max-width: 1024px) {
    .menu-foot .hamburger {
        >strong {
            background: $verdeClaro;
            padding: 10px 10px;
            display: block;
        }

        >span {
            display: none;
        }
    }

    .MuiButton-root.bt.piso-superior-mobile {
        display: block;
        font-size: 12px;
        padding: 6px 10px;
        position: absolute;
        bottom: 15px;
        left: 15px;
    }

    .menu-foot .hamburger.is-active {
        span {
            display: block;
        }

        strong {
            display: none;
        }
    }
}