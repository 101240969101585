$colorDark: var(--color-dark);
$colorLight: var(--color-light);

$riscoDark: rgb(68,50,71);
$riscoLight: rgb(122, 82, 129);

$produtividadeDark: rgb(0, 68, 34);
$produtividadeLight: rgb(3, 97, 50);

$negocioDark: rgb(16,56,79);
$negocioLight: rgb(21, 90, 129);

$inovacaoDark: rgb(137,211,41);
$inovacaoLight: rgb(172, 236, 88);

/* Handle */
::-webkit-scrollbar-thumb {
    background: $colorDark;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8b8686;
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.item-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .MuiButton-root.bt {
        background: $colorDark;
        color: #fff;
        border-radius: 100px;
        width: 100%;
        position: relative;
        font-size: 17px;
        font-weight: normal;
        text-transform: uppercase;

        &.especialista {
            margin-top: 10px;
            text-transform: none;
            background: rgba($color: $colorDark, $alpha: 0.5);

            &:hover {
                background: #fff;
                color: $colorDark;
            }
        }

        &.mais {
            background: rgba($color: $colorDark, $alpha: 0);
            color: $colorDark;
            border: solid 2px $colorDark;

            &:hover {
                background: $colorDark;
                color: #fff;
                transition-delay: 0ms;

                svg {
                    transform: rotate(720deg);
                }
            }
        }

        svg {
            position: absolute;
            left: 8px;
            top: 6px;
            font-size: 220%;
            background: none;
            color: unset;
            transform: rotate(0deg);
            @include expoOut();
            transition-duration: 2s;
        }

        &:hover {
            background: #fff;
            color: $colorDark;
        }
    }

    &.produtividade {

        .box-right h3,
        .box-right .box strong,
        .box-right .cards .card:not(.red) h2 {
            color: $produtividadeDark;
        }

        .box-right {
            //background: $produtividadeLight;
        }

        .box-right .cards .card.red,
        .MuiButton-root.bt,
        .box-right .cards .card:not(.red) svg {
            background: $produtividadeDark;
        }

        .MuiButton-root.bt {
            &:hover {
                background: #fff;
                color: $produtividadeDark;
            }

            &.especialista {
                background: rgba($produtividadeDark, $alpha: 0.5);

                &:hover {
                    background: #fff;
                    color: $produtividadeDark;
                }
            }

            &.mais {
                background: rgba($produtividadeDark, 0);
                color: $produtividadeDark;
                border: solid 2px $produtividadeDark;

                &:hover {
                    background: $produtividadeDark;
                    color: #fff;
                }
            }
        }
    }

    &.risco {

        .box-right h3,
        .box-right .box strong,
        .box-right .cards .card:not(.red) h2 {
            color: $riscoDark;
        }

        .box-right {
            //background: $riscoLight;
        }

        .box-right .cards .card.red,
        .MuiButton-root.bt,
        .box-right .cards .card:not(.red) svg {
            background: $riscoDark;
        }

        .MuiButton-root.bt {
            &:hover {
                background: #fff;
                color: $riscoDark;
            }

            &.especialista {
                background: rgba($riscoDark, $alpha: 0.5);

                &:hover {
                    background: #fff;
                    color: $riscoDark;
                }
            }

            &.mais {
                background: rgba($riscoDark, 0);
                color: $riscoDark;
                border: solid 2px $riscoDark;

                &:hover {
                    background: $riscoDark;
                    color: #fff;
                }
            }
        }
    }

    &.negocios {

        .box-right h3,
        .box-right .box strong,
        .box-right .cards .card:not(.red) h2 {
            color: $negocioDark;
        }

        .box-right {
            //background: $negocioLight;
        }

        .box-right .cards .card.red,
        .MuiButton-root.bt,
        .box-right .cards .card:not(.red) svg {
            background: $negocioDark;
        }

        .MuiButton-root.bt {
            &:hover {
                background: #fff;
                color: $negocioDark;
            }

            &.especialista {
                background: rgba($negocioDark, $alpha: 0.5);

                &:hover {
                    background: #fff;
                    color: $negocioDark;
                }
            }

            &.mais {
                background: rgba($negocioDark, 0);
                color: $negocioDark;
                border: solid 2px $negocioDark;

                &:hover {
                    background: $negocioDark;
                    color: #fff;
                }
            }
        }
    }

    &.inovacao,
    &.fieldview {

        .box-right h3,
        .box-right .box strong,
        .box-right .cards .card:not(.red) h2 {
            color: $inovacaoDark;
        }

        .box-right {
            //background: $inovacaoLight;
        }

        .box-right .cards .card.red,
        .MuiButton-root.bt,
        .box-right .cards .card:not(.red) svg {
            background: $inovacaoDark;
        }

        .MuiButton-root.bt {
            &:hover {
                background: #fff;
                color: $inovacaoDark;
            }

            &.especialista {
                background: rgba($inovacaoDark, $alpha: 0.5);

                &:hover {
                    background: #fff;
                    color: $inovacaoDark;
                }
            }

            &.mais {
                background: rgba($inovacaoDark, 0);
                color: $inovacaoDark;
                border: solid 2px $inovacaoDark;

                &:hover {
                    background: $inovacaoDark;
                    color: #fff;
                }
            }
        }
    }

    .img {
        width: 65%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        box-shadow: inset 0 0 150px rgba(0, 0, 0, 0.7);
        position: relative;

        >div {
            height: 100% !important;
        }

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 150px;
        }

        iframe {
            margin: 0;
            width: 100%;
            height: 100%;
        }
    }

    .box-right {
        width: 35vw;
        background: #fff;
        text-align: left;
        padding: 15px;
        overflow-y: auto;
        height: 100%;

        h3 {
            color: $colorDark;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: -5px;
            padding-right: 20px;
        }

        h4 {
            color: #8b8686;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: -5px;
        }

        p {
            font-size: 14px;
            color: #000;
        }

        ol {
            margin-top: -5px;

            li {
                font-size: 14px;
                color: #000;
                margin-bottom: 5px;

                strong {
                    color: $colorDark;
                    font-weight: 500;
                }
            }
        }

        .box {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            padding: 10px 15px;
            margin-top: 15px;
            margin-bottom: 15px;

            strong {
                color: $colorDark;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 10px;
                display: block;
            }

            p {
                margin: 0;
                margin-bottom: 10px;
            }

            ol {
                list-style: disc;
                padding-left: 17px;

                li {
                    &::marker {
                        color: $colorDark;
                    }
                }
            }
        }

        .cards {
            padding: 15px 0;

            .card {
                color: #000;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                width: calc(50% - 10px);
                position: relative;
                padding: 10px 15px;
                background: #fff;

                small {
                    font-weight: 500;
                }

                &.red {
                    background: $colorDark;
                    color: #fff;
                }

                h2 {
                    font-size: 42px;
                    font-weight: 500;
                    margin-bottom: -7px;
                    margin-top: -5px;
                }

                &:not(.red) {
                    svg {
                        background: $colorDark;
                        color: #fff;
                        border-radius: 100%;
                    }

                    h2 {
                        color: $colorDark;
                    }
                }

                svg {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }
    }
}

// tablet
@media (max-width: 1024px) {
    .item-slide .img {
        width: 100%;
        height: 35%;
        min-height: 35%;
    }

    .item-slide .box-right {
        width: 100%;
        height: 65%;
        min-height: 65%;
        padding: 15px 20%;
    }

    .item-slide .img img {
        width: 145px;
    }

    .item-slide {
        &.is-playing-true {
            .img {
                width: 100vw !important;
            }

            .box-right {
                //width: 0 !important;
                //padding: 0 !important;
            }
        }
    }

}

// mobile
@media (max-width: 1024px) {
    .item-slide {
        flex-wrap: wrap;
    }

    .item-slide .img {
        width: 100%;
        height: 25%;
        min-height: 25%;
        overflow: hidden;
    }

    .item-slide .img img {
        width: 100px;
    }

    .item-slide .box-right {
        width: 100%;
        height: 75%;
        min-height: 75%;
        padding: 15px;
    }

    .item-slide .box-right h3 {
        padding-right: 170px;
    }

    .item-slide .box-right .cards .card small {
        font-size: 12px;
    }

    .item-slide .box-right .cards .card h2 {
        font-size: 33px;
    }

    .item-slide .box-right .cards .card {
        width: 100%;
        margin-bottom: 15px;
    }
}