@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

$verdeClaro: #89fa4f;
$dark: #223b51;

.show-powered,
.entrada {
    .msg-hover {
        margin-bottom: 100px;
    }    
}

.msg-hover {
    position: absolute;
    bottom: -200px;
    right: 0;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    color: $dark;
    opacity: 0;
    @include expoOut();
    transition-duration: 0ms;
    z-index: 999;

    &.active {
        opacity: 1;
        bottom: 0;
        transition-duration: 700ms;
    }

    .msg-hover-balao {
        background-color: $verdeClaro;
        padding: 20px;
        border-radius: 7px;
    }
}