$colorDark: var(--color-dark);
$colorLight: var(--color-light);

/* Handle */
::-webkit-scrollbar-thumb {
    background: $colorDark;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8b8686;
}

$verdeClaro: #89fa4f;
$verdeEscuro: #003851;

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

.arena {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;

    &.animation-true {
        .area-transform .carousel-root .slider-wrapper ul li:first-child .item-slide .img {
            left: 0;
        }

        .area-transform .carousel-root .slider-wrapper ul li:first-child .item-slide .box-right {
            right: 0;
        }

        .area-transform .carousel-root .carousel .control-prev.control-arrow {
            left: 5px;
            opacity: 1;
        }

        .area-transform .carousel-root .carousel .control-next.control-arrow {
            right: calc(35vw + 15px);
            opacity: 1;
        }

        .area-transform .carousel-root .carousel .control-dots {
            opacity: 1;
            bottom: 0;
        }

        .area-transform img.logo {
            top: 0;
        }

        .area-transform .carousel-root .slider-wrapper ul li:first-child .item-slide .box-right {

            h3,
            h4,
            p,
            ol,
            .box,
            .cards,
            .MuiButton-root.bt {
                right: 0;
                opacity: 1;
            }
        }
    }

    &.curso-true {
        ul.control-dots {
            display: none;
        }

        .carousel .control-next.control-arrow.control-disabled,
        .carousel .control-prev.control-arrow.control-disabled {
            display: none;
        }
    }

    .area-transform {
        position: relative;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        transform: scale(1) translate(0, 0);
        @include expoOut();
        transition-duration: 1.5s;
        z-index: 999;

        &.mini {
            transform: var(--transform);
            z-index: 98;
        }

        .carousel-root {
            width: 100%;
            height: 100%;

            .slider-wrapper {
                position: absolute;
                top: 0;
                height: 100%;

                ul {
                    li {
                        background: none;

                        &:first-child {
                            .item-slide {
                                .img {
                                    position: relative;
                                    left: -100%;
                                    @include expoOut();
                                    transition-duration: 1s;
                                }

                                .box-right {
                                    position: relative;
                                    right: -100%;
                                    @include expoOut();
                                    transition-duration: 1s;
                                    overflow-x: hidden;

                                    h3 {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 500ms;
                                    }

                                    h4 {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 600ms;
                                    }

                                    >p {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 700ms;
                                    }

                                    ol {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 800ms;
                                    }

                                    .box {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 900ms;
                                    }

                                    .cards {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();
                                        transition-duration: 1s;
                                        transition-delay: 1000ms;
                                    }

                                    &:not(:hover) {
                                        .MuiButton-root.bt {
                                            transition-duration: 1s;
                                            transition-delay: 1000ms;
                                        }
                                    }

                                    .MuiButton-root.bt {
                                        position: relative;
                                        right: -100%;
                                        @include expoOut();

                                        &.especialista {
                                            margin-top: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .item-slide {
                            .img {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }

            .carousel {
                overflow: hidden;
                width: 100%;
                height: 100%;

                .slider,
                .slide {
                    height: 100%;
                }

                .control-prev.control-arrow {
                    opacity: 0;
                    left: 100px;
                    @include expoOut();
                    transition-duration: 1s;
                    transition-delay: 500ms;
                }

                .control-next.control-arrow {
                    opacity: 0;
                    right: calc(35vw + 115px);
                    @include expoOut();
                    transition-duration: 1s;
                    transition-delay: 500ms;
                }

                .control-dots {
                    bottom: -100px;
                    opacity: 0;
                    @include expoOut();
                    transition-duration: 1s;
                    transition-delay: 600ms;
                }
            }
        }

        img.logo {
            width: auto;
            position: absolute;
            top: -100px;
            left: 15px;
            width: 160px;
            @include expoOut();
            transition-duration: 1s;
            transition-delay: 700ms;
        }

        .bt-full {
            position: absolute;
            right: calc(35vw + 10px);
            top: 10px;
            background: rgba($color: #fff, $alpha: 0.5);
            color: #000;
            border-radius: 100px;
            padding: 0 10px;
            font-size: 12px;
            text-transform: none;
            font-weight: normal;
        }

        .bt-fechar {
            position: absolute;
            top: 5px;
            right: 5px;
            color: rgba($color: #000000, $alpha: 0.2);
            padding: 0;
            min-width: auto;
            min-height: auto;
            border-radius: 100%;
            background: none;

            &:hover {
                svg {
                    color: $colorDark;
                }
            }

            svg {
                font-size: 40px;
                @include easeOut();
            }
        }
    }

    .carousel .control-next.control-arrow {
        right: 35%;
    }

    .carousel .control-dots {
        width: 65%;
    }

    .carousel .control-dots .dot.selected,
    .carousel .control-dots .dot:hover {
        background: $colorDark;
        transform: scale(2);
    }

    .carousel .control-dots .dot {
        opacity: 1;
        transform: scale(1);
        box-shadow: none;
        margin: 0 10px;
        @include easeOut();
    }

    .carousel .control-next.control-arrow {
        width: 50px;
        height: 50px;
        top: 50%;
        right: calc(35vw + 15px);
        border: solid 3px $verdeClaro;
        background: $verdeClaro;
        color: $verdeEscuro;
        border-radius: 100%;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.control-disabled {
            opacity: 0.3;
            pointer-events: none;
            display: none;
        }

        &::before {
            background: url(../../assets/arenas/seta-carousel.svg) no-repeat;
            width: 20px;
            height: 30px;
            border: none;
            margin: 0;
            transform: rotate(180deg);
            position: relative;
        }

        &:hover {
            background: #fff;
            border-color: #fff;
            transition-delay: 0ms;
        }
    }

    .carousel .control-prev.control-arrow {
        width: 50px;
        height: 50px;
        top: 50%;
        left: 15px;
        border: solid 3px $verdeClaro;
        border-radius: 100%;
        background: $verdeClaro;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &.control-disabled {
            opacity: 0.3;
            pointer-events: none;
            display: none;
        }

        &:hover {
            background: #fff;
            border-color: #fff;
            transition-delay: 0ms;
        }

        &::before {
            background: url(../../assets/arenas/seta-carousel.svg) no-repeat;
            width: 20px;
            height: 30px;
            border: none;
            margin: 0;
            position: relative;
        }
    }
}

// mobile
@media (max-width: 1024px) {
    .arena .area-transform .carousel-root .slider-wrapper ul li .item-slide .img {
        width: 100%;
        height: 25%;
        min-height: 25%;
        overflow: hidden;
    }

    .arena .area-transform .carousel-root .slider-wrapper ul li .item-slide .img img {
        width: 100px;
    }

    .arena .area-transform .carousel-root .slider-wrapper ul li .item-slide .box-right {
        width: 100%;
        height: 75%;
        min-height: 75%;
        padding: 15px;
    }

    .arena .carousel .control-dots,
    .arena.animation-true .area-transform .carousel-root .carousel .control-dots {
        width: 70%;
        top: 18%;
        bottom: auto;
    }

    .arena .carousel .control-dots .dot {
        margin: 0 7px;
    }

    .arena .carousel .control-next.control-arrow {
        top: 12%;
        bottom: auto;
        right: auto;
        left: 15%;
        width: 30px;
        height: 30px;
    }

    .arena .carousel .control-prev.control-arrow {
        top: 12%;
        bottom: auto;
        right: auto;
        left: 15px;
        width: 30px;
        height: 30px;
    }

    .arena .area-transform .bt-full {
        right: 50px;
        top: 13px;
    }

    .arena .area-transform .carousel-root .slider-wrapper ul li .item-slide .box-right h3 {
        padding-right: 0;
    }

    .item-slide .box-right .cards .card {
        width: 100%;
        margin-bottom: 15px;
    }

    .arena .area-transform img.logo {
        width: calc(22vw - 30px);
    }

    .arena .carousel .control-next.control-arrow::before {
        width: 100%;
        height: 100%;
        left: -2px;
    }

    .arena .carousel .control-prev.control-arrow::before {
        width: 100%;
        height: 100%;
        left: 2px;
    }

    .arena .area-transform .bt-fechar {
        top: calc(25vh + 10px);
    }

    div.item-slide .img iframe {
        height: 100%;
    }
}

@media (max-width: 1024px) {
    .arena.animation-true .area-transform .carousel-root .slider-wrapper ul li .item-slide .box-right {
        padding-top: 50px;
    }

    .arena .area-transform .bt-fechar {
        top: 10px;
        color: $verdeClaro;
    }

    .arena .carousel .control-next.control-arrow,
    .arena .carousel .control-prev.control-arrow {
        top: calc(20vh + 40px);
    }

    .arena.animation-true .area-transform .carousel-root .carousel .control-next.control-arrow {
        left: auto;
        right: 15px;

        &:hover {
            background: $verdeClaro;
        }
    }

    .arena.animation-true .area-transform .carousel-root .carousel .control-prev.control-arrow {
        left: 15px;
        right: auto;

        &:hover {
            background: $verdeClaro;
        }
    }
}