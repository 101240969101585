@media (max-width: 1024px) {
    body.produtividade {

        section.produtividade {
            background: url(../../assets/panolens/produtividade.jpg) no-repeat 65% center/cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

    }
}