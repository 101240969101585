@mixin expoOut {
    -webkit-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}


$dark: #003851;
$verdeClaro: #89fa4f;
$azulClaro: #4bd3ed;

.modal-forum {
    position: absolute;
    z-index: 9999;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0);
    height: 100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    @include easeOut();

    &.animation-true {
        background-color: rgba($color: #000000, $alpha: 0.7);
        opacity: 1;
        visibility: visible;

        .box {
            top: 0;
        }
    }

    .box {
        position: relative;
        top: 100px;
        @include expoOut();
        transition-duration: 1s;
        transition-delay: 200ms;
        color: #fff;
        background-color: $azulClaro;
        border-radius: 40px;
        padding: 20px 35px 0 20px;

        .box-img {
            img {
                max-height: 230px;
            }
        }

        .box-form {
            max-width: 430px;
            margin-bottom: 20px;
        }

        p.MuiFormHelperText-root {
            color: red;
            font-size: 12px;
            font-weight: 400;
        }
    }

    .campos {
        padding-top: 15px;

        .MuiFormControl-root {
            width: 100%;
            margin-bottom: 5px;
        }

        label.Mui-focused {
            color: $dark;
        }

        .MuiOutlinedInput-root {
            fieldset {
                border-color: $dark;
            }
        }
    }

    h2 {
        font-weight: 500;
        color: $dark;
    }

    p {
        font-weight: 300;

        span {
            font-weight: 500;
        }
    }

    .bts {
        padding-top: 20px;

        .MuiFormControlLabel-root {
            margin-bottom: -10px;
            position: relative;
            top: -18px;

            .MuiTypography-root {
                font-size: 13px;
            }

            .MuiCheckbox-colorSecondary.Mui-checked {
                color: $dark;
            }
        }

        .bt {
            background: $dark;
            color: #fff;
            border-radius: 100px;
            width: calc(50% - 10px);
            position: relative;
            font-size: 17px;
            font-weight: normal;
            cursor: pointer;

            &:hover {
                background: $verdeClaro;
                color: $dark;
            }

            &.cad {
                margin-left: 20px;
                background: rgba($color: $dark, $alpha: 0);
                border: solid 1px $dark;
                color: $dark;

                &:hover {
                    background: $dark;
                    color: #fff;
                }
            }
        }
    }
}

.MuiButtonBase-root.closeModal {
    position: absolute;
    right: -8px;
    top: -8px;
    background-color: white;
    border-radius: 50%;
    min-width: 35px;
    min-height: 35px;
    height: 35px;
    width: 35px;
    z-index: 30;
    padding: 3px;
    cursor: pointer;
    @include easeOut();

    &:hover {
        background: $verdeClaro;
    }

    &::after {
        content: '';
        background-image: url(../modal/close.svg);
        width: 18px;
        height: 30px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }
}

@media (max-width: 1024px) {

    body .modal-forum .box {
        max-width: 90%;
        max-height: 90%;
        padding: 20px 20px 0 20px;
    }

    body .modal-forum .box .box-form {
        padding: 0 30px;
    }

    body .modal-forum .box .box-img img {
        display: none;
    }

    body .modal-forum .bts .bt {
        padding: 0;
    }

    body .modal-forum .bts .MuiFormControlLabel-root .MuiTypography-root {
        font-size: 11px;
    }
}